import "react-datepicker/dist/react-datepicker.css";

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckButton from "react-validation/build/button";
import DatePicker from "react-datepicker";
import Form from "react-validation/build/form";
import GetLocation from "./GetLocation";
import Input from "react-validation/build/input";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
import { atypical } from "./../../../actions/auth";
import { setShowA } from "./../../../actions/modalActions";
import { useTranslation } from "react-i18next";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const AtypicalActModal = ({
  handleReportsUpdated,
  title,
  show,
  handleClose,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().setHours(new Date().getHours(), new Date().getMinutes())
    )
  );
  const form = useRef();
  const checkBtn = useRef();

  const dispatch = useDispatch();

  const containerStyle = {
    width: "100%",
    height: "150px",
    border: "2px dashed #007BFF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  };

  const labelStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#007BFF",
  };

  const [reportName, setreportName] = useState("");
  const [userId, setuserId] = useState("");
  const [stormTypeId, setstormTypeId] = useState("");
  const [comment, setComment] = useState("");
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);

  const [reportPhotoString, setBaseImage] = useState([]);
  const [fileInput, setFileInput] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploading, setUploading] = useState(false);

  const inputRef = useRef();

  const { t } = useTranslation();

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === "file") {
        const file = e.dataTransfer.items[0].getAsFile();
        handleFile(file);
      }
    } else {
      const file = e.dataTransfer.files[0];
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    setUploading(true);
    setFileInput(true);
    setUploadedFileName(file.name);
    try {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  if (!show) {
    return null;
  }

  const onChangeReportName = (e) => {
    const reportName = e.target.value;
    setreportName(reportName);
  };

  const onChangeStormType = (stormTypeId) => {
    setstormTypeId(stormTypeId.value);
  };

  const onChangeComment = (e) => {
    const comment = e.target.value;
    setComment(comment);
  };

  const handleReportAtypical = (e) => {
    e.preventDefault();

    form.current.validateAll();

    dispatch(setShowA(false));

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        atypical(
          stormTypeId,
          userId,
          reportName,
          startDate,
          latitude,
          longitude,
          comment,
          reportPhotoString || ""
        )
      )
        .then(() => {
          // setSuccessful(true);
          // dispatch(setMessage('BRAO'));
          handleReportsUpdated(true);
          setFileInput(false);
        })
        .catch(() => {
          handleReportsUpdated(true);
        });
    }
  };

  return (
    <div
      className="modal-container"
      onClick={() =>
        dispatch(
          setShowA(false),
          setShowA(false)
            ? (document.body.style.overflow = "auto")
            : (document.body.style.overflow = "hidden")
        )
      }
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleReportAtypical} ref={form}>
          <div className="modal-header">
            <div className="modal-title">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="ds-block username-field mb-3">
                {t("modals.jellyM.reportTitle")}
                <Input
                  type="text"
                  name="reportName"
                  onChange={onChangeReportName}
                  validations={[required]}
                ></Input>
              </label>
              <label className="mb-3 w-100">
                {" "}
                {t("modals.jellyM.dateAndTime")}
                <DatePicker
                  name="reportDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  readOnly
                  // onChange={onChangeDateTime}
                />
              </label>
              <GetLocation
                latitude={latitude}
                longitude={longitude}
                setLat={setLat}
                setLng={setLng}
              />
              <div class="alert alert-warning" role="alert">
                {t("modals.jellyM.infoGeolocation")}
              </div>
              <label className="ds-block ds-coast-field mb-3">
                {t("modals.jellyM.stormType")}
                <Select
                  id="jellyselect"
                  defaultValue={{ label: t("modals.jellyM.pleaseSelect") }}
                  name="stormTypeId"
                  options={[
                    { value: "1", label: t("modals.jellyM.optionStorm") },
                    {
                      value: "2",
                      label: t("modals.jellyM.optionThunderstorm"),
                    },
                    { value: "3", label: t("modals.jellyM.optionRain") },
                    { value: "4", label: t("modals.jellyM.optionHailstorm") },
                    { value: "5", label: t("modals.jellyM.optionNostorm") },
                  ]}
                  onChange={onChangeStormType}
                  isSearchable={false}
                />
              </label>
              <label className="ds-block comments-field mb-3">
                {t("modals.jellyM.des")}
                <Input
                  type="text"
                  name="comment"
                  onChange={onChangeComment}
                  validations={[required]}
                ></Input>
              </label>
              {/* <UploadAndDisplayImage /> */}
              <p>{t("modals.jellyM.attachImage")}</p>
              <div
                className="file-upload-container"
                onClick={() => inputRef.current.click()}
                style={containerStyle}
                onDragOver={onDragOver}
                onDrop={onDrop}
              >
                <input
                  type="file"
                  id="fileInput"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <label
                  htmlFor="fileInput"
                  className="file-upload-label"
                  style={labelStyle}
                >
                  {uploading
                    ? "Loading..."
                    : uploadedFileName
                    ? uploadedFileName
                    : t("modals.dragndrop")}
                </label>
              </div>
              {/* <FileBase64
                multiple={true}
                onDone={(files) => onFileChange(files)}
              /> */}
              <br></br>
            </div>
          </div>
          <div className="modal-footer">
            {stormTypeId !== "" && fileInput ? (
              <button className="sub-report">
                {t("modals.jellyM.submitReport")}
              </button>
            ) : (
              <div className="text-left text-danger">
                {fileInput
                  ? t("modals.jellyM.selectStorm")
                  : t("modals.jellyM.selectFile")}
              </div>
            )}
            {/* <button className="sub-report">Submit Report</button> */}
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <button
          className="btn-colse-modal"
          onClick={() => dispatch(setShowA(false))}
        ></button>
      </div>
    </div>
  );
};

export default AtypicalActModal;
