import React from 'react';
import '../Styles/Login.css';
import ForgotPassForm from './ForgotPassForm';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <div className="login-container">
      <div className="login-wrapper forgot-password-wrapper">
        {/* <div className='second-waves'></div> */}
        <div className="info-form">
          <p>{t('login.fieldsSignIn.forgotPasswordMessage1')}</p>
          <p>{t('login.fieldsSignIn.forgotPasswordMessage2')}</p>
        </div>
        <h1 className="title-forgot-pass">
          {t('login.fieldsSignIn.forgotPassword')}
        </h1>
        {/* <p className='sub-title'>If you don't have an account you can <a className='register-accout' href='/register'>Register</a> now!</p> */}
        <div className="form-group">
          {/* Login Form */}
          <ForgotPassForm />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
