import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const modalClass = isOpen ? 'modal-contaner-c open' : 'modal-contaner-c';

  return (
    <div className={modalClass}>
      <div className="modal-wrapper">
        <span className="btn-colse-modal" onClick={onClose}></span>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
