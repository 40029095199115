import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import configUrl from '../configuration-data.json';

const ForgotPassForm = (props) => {
  const [formData, setFormData] = useState({});
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      const encodedEmail = encodeURIComponent(data.emailField);
      const url = `${configUrl.SERVER_URL}PasswordReset/RequestPasswordReset?email=${encodedEmail}`;

      const axiosConfig = {
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      const response = await axios(axiosConfig);

      setIsEmailSent(true);
      setFormData(data);
    } catch (error) {
      console.error('Error when the email is sent:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="ds-block email-field">
        {t('login.fieldsSignIn.enterValidEmail')}
      </label>
      <input
        type="text"
        {...register('emailField', {
          required: true,
          maxLength: 100,
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        })}
      />
      {errors?.emailField?.type === 'required' && (
        <p className="error-messages">
          {t('login.fieldsSignIn.requiredFieldError')}*
        </p>
      )}
      {errors?.emailField?.type === 'maxLength' && (
        <p className="error-messages">
          {t('login.fieldsSignIn.emailErrorCharacters')}*
        </p>
      )}
      {errors?.emailField?.type === 'pattern' && (
        <p className="error-messages">
          {t('login.fieldsSignIn.validEmailAddressError')}*
        </p>
      )}
      {isEmailSent && (
        <div class="alert alert-success mt-2" role="alert">
          {t('login.fieldsSignIn.successEmailmsg')}
          <span className="font-weight-bold"> {formData.emailField} </span>!
        </div>
      )}
      <div className="form-group">
        {isEmailSent ? (
          <button className="submit-email-btn">
            {t('login.fieldsSignIn.resendEmail')}
          </button>
        ) : (
          <button className="submit-email-btn">
            {t('login.fieldsSignIn.sendEmail')}
          </button>
        )}
      </div>
    </form>
  );
};

export default ForgotPassForm;
