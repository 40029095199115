import "../Styles/NavMenuNew.css";

import { Link, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import bulgarian from "../Assetss/bulgaria.png";
import { clearMessage } from "../actions/message";
import english from "../Assetss/united-kingdom.png";
import i18n from "i18next";
import jwt from "jwt-decode";
import logoIcc from "../Assetss/ICC-logo1.png";
import { logout } from "../actions/auth";
import russia from "../Assetss/russia.png";
import { useTranslation } from "react-i18next";

const NavMenu = () => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { t } = useTranslation([]);
  const [lastLanguage, setLastLanguage] = useState("bg");
  const [currentLanguage, setCurrentLanguage] = useState(lastLanguage);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    const rootsE = ["/login", "/register"];
    const testE = rootsE || "";
    if (testE.includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      const userType = currentUser;
      const usrT = userType || {};
      let decodeUser = jwt(usrT.token);
      let usType = decodeUser || {};
      if (usType.role.includes("Admin")) {
        setShowAdminBoard(true);
        // console.log('is admin');
      } else {
        setShowAdminBoard(false);
        // console.log('is not admin');
      }
    }
  }, [currentUser]);

  const handleLanguageTypeChange = (selectedOption) => {
    setCurrentLanguage(selectedOption.value);
  };

  useEffect(() => {
    if (currentLanguage !== lastLanguage) {
      i18n.changeLanguage(currentLanguage);

      setLastLanguage(currentLanguage);
      localStorage.setItem("language", currentLanguage);
    }
  }, [currentLanguage, lastLanguage]);

  const options = [
    {
      value: "BG",
      label: (
        <div>
          <img
            src={bulgarian}
            className="mr-0-5"
            alt="BG"
            width={24}
            height={24}
          />
          BG
        </div>
      ),
    },
    {
      value: "EN",
      label: (
        <div>
          <img
            src={english}
            className="mr-0-5"
            alt="EN"
            width={24}
            height={24}
          />
          EN
        </div>
      ),
      style: {
        backgroundImage: "url(path/to/en-flag.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        paddingLeft: "30px",
      },
    },
    {
      value: "RU",
      label: (
        <div>
          <img
            src={russia}
            className="mr-0-5"
            alt="RU"
            width={24}
            height={24}
          />
          RU
        </div>
      ),
    },
  ];

  return (
    <header>
      <nav className="navbar wave">
        <div className="darkblue-wave"></div>
        <div className="light-wave"></div>
        <div className="bluegreen-wave"></div>
        <div className="navbar-wrapper">
          <div className="logo-image-container">
            <Link to="/">
              <img src={logoIcc} alt="Icc logo" />
            </Link>
          </div>
          <Select
            options={options}
            defaultValue={{
              label: (
                <div>
                  <img
                    src={
                      localStorage.getItem("language", "BG") === "EN"
                        ? english
                        : bulgarian
                    }
                    className="mr-0-5"
                    alt="EN"
                    width={24}
                    height={24}
                  />
                  {localStorage.getItem("language", "BG")}
                </div>
              ),
            }}
            onChange={handleLanguageTypeChange}
            styles={{
              option: (provided) => ({
                ...provided,
                backgroundSize: "contain",
              }),
            }}
          />
          {currentUser ? (
            <div className="button-nav">
              <div className="buttons-box mr-3">
                <Link to={"/user-profile"}></Link>
                <Link to="/login" onClick={logOut}>
                  {t("header.signout")}
                </Link>
              </div>
            </div>
          ) : (
            <div className="button-nav">
              <div className="buttons-box">
                <Link to={"/login"}>{t("header.login")}</Link>
              </div>
            </div>
          )}
        </div>
      </nav>
      {currentUser && (
        <div className="account-contaner">
          <div className="account-wapper">
            <div className="account__navigation">
              <div className="account--option">
                <ul className="il_menu__wrap m-0">
                  <li>
                    <Link to="/user-profile" className="link-account">
                      <span className="icon icon-account"></span>
                      {t("header.profile")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-history" className="link-account">
                      <span className="icon icon-history"></span>
                      {t("header.history")}
                    </Link>
                  </li>
                  {showAdminBoard && (
                    <li>
                      <Link to="/admin" className="link-account">
                        <span className="icon icon-admin"></span>
                        {t("header.adminBoard")}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </header>
  );
};

export default NavMenu;
