import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  JELLYFISH_REPORT_POST_SUCCESS,
  JELLYFISH_REPORT_POST_FAIL,
  ATYPICAL_REPORT_POST_SUCCESS,
  ATYPICAL_REPORT_POST_FAIL,
  OTHER_REPORT_POST_SUCCESS,
  OTHER_REPORT_POST_FAIL,
  METEOROLOGICAL_PARAM_REPORT_POST_SUCCESS,
  METEOROLOGICAL_PARAM_REPORT_POST_FAIL,
  POLLUTANTS_REPORT_POST_SUCCESS,
  POLLUTANTS_REPORT_POST_FAIL,
} from '../actions/types';

// const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: JSON.parse(localStorage.getItem('user')),
  jellyfish: JSON.parse(localStorage.getItem('jellyfish')),
  atypical: JSON.parse(localStorage.getItem('atypical')),
  baseReport: JSON.parse(localStorage.getItem('baseReport')),
  meteorologicalParam: JSON.parse(localStorage.getItem('meteorologicalParam')),
  pollutants: JSON.parse(localStorage.getItem('pollutants')),
};
// ? { isLoggedIn: true, user }
// : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case JELLYFISH_REPORT_POST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        jellyfish: payload.jellyfish,
      };
    case JELLYFISH_REPORT_POST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        jellyfish: null,
      };
    case ATYPICAL_REPORT_POST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        atypical: payload.atypical,
      };
    case ATYPICAL_REPORT_POST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        atypical: null,
      };
    case OTHER_REPORT_POST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        baseReport: payload.baseReport,
      };
    case OTHER_REPORT_POST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        baseReport: null,
      };
    case METEOROLOGICAL_PARAM_REPORT_POST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        meteorologicalParam: payload.meteorologicalParam,
      };
    case METEOROLOGICAL_PARAM_REPORT_POST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        meteorologicalParam: null,
      };
    case POLLUTANTS_REPORT_POST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        pollutants: payload.meteorologicalParam,
      };
    case POLLUTANTS_REPORT_POST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        pollutants: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
