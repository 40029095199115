import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Profile from './Profile';

import '../../Styles/user-profile/user-accunt.css';

function AccountUser() {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="account-contaner">
      <Profile />
    </div>
  );
}

export default AccountUser;
