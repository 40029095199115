import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckButton from "react-validation/build/button";
import DatePicker from "react-datepicker";
import Form from "react-validation/build/form";
import GetLocation from "./GetLocation";
import Input from "react-validation/build/input";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
import { meteorologicalParam } from "./../../../actions/auth";
import { setShowM } from "./../../../actions/modalActions";
import { useTranslation } from "react-i18next";

const MeteorologicalParamModal = ({
  handleReportsUpdated,
  title,
  show,
  handleClose,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().setHours(new Date().getHours(), new Date().getMinutes())
    )
  );

  const form = useRef();
  const checkBtn = useRef();

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [reportName, setreportName] = useState("");
  const [userId, setuserId] = useState("");
  const [comment, setComment] = useState("");
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [fogTypeId, setfogTypeId] = useState("");
  const [seaSwellTypeId, setseaSwellTypeId] = useState("");
  const [windTypeId, setwindTypeId] = useState("");
  const [reportPhotoString, setBaseImage] = useState([]);
  const [validateForm, setValidateForm] = useState(false);
  const [successMessage, setsuccessMessage] = useState(false);

  const { t } = useTranslation();

  const [fileInput, setFileInput] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const containerStyle = {
    width: "100%",
    height: "150px",
    border: "2px dashed #007BFF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  };

  const labelStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#007BFF",
  };

  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    setuserId(currentUser.userName);
  }, [currentUser.userName]);

  useEffect(() => {
    if (message === "success") {
      // clearForm();
      setValidateForm(false);
    }
  }, [message]);

  const required = (value) => {
    if (!value && validateForm) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  if (!show) {
    return null;
  }

  const onChangeReportName = (e) => {
    const reportName = e.target.value;
    setreportName(reportName);
  };

  const onChangeComment = (e) => {
    const comment = e.target.value;
    setComment(comment);
  };

  const onChangeFogType = (fogTypeId) => {
    setfogTypeId(fogTypeId.value);
  };

  const onChangeSeaSwellType = (seaSwellTypeId) => {
    setseaSwellTypeId(seaSwellTypeId.value);
  };

  const onChangeWindType = (windTypeId) => {
    setwindTypeId(windTypeId.value);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      if (e.dataTransfer.items[0].kind === "file") {
        const file = e.dataTransfer.items[0].getAsFile();
        handleFile(file);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      const file = e.dataTransfer.files[0];
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    // Essentially the same logic as onFileChange but for the dropped file
    setUploading(true);

    setFileInput(true);
    setUploadedFileName(file.name);

    try {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const onFileChange = (e) => {
    setUploading(true);

    if (e.target.files[0]) {
      setFileInput(true);
      setUploadedFileName(e.target.files[0].name);
    }
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const handleReportMeteologicalParam = (e) => {
    e.preventDefault();

    if (
      (!fogTypeId && !seaSwellTypeId && !windTypeId) ||
      !userId ||
      !reportName ||
      !startDate ||
      latitude === null ||
      longitude === null ||
      !comment ||
      !reportPhotoString.length ||
      !fileInput
    ) {
      setValidateForm(true);
      setDisplayError(true);
      return;
    }

    if (checkBtn.current.context._errors.length === 0) {
      setsuccessMessage(true);

      dispatch(setShowM(false));

      dispatch(
        meteorologicalParam(
          fogTypeId,
          seaSwellTypeId,
          windTypeId,
          userId,
          reportName,
          startDate,
          latitude,
          longitude,
          comment,
          reportPhotoString || ""
        )
      )
        .then(() => {
          handleReportsUpdated(true);
          setFileInput(false);
        })
        .catch((e) => {
          handleReportsUpdated(true);
        });
    }
  };

  return (
    <div
      className="modal-container"
      onClick={() =>
        dispatch(
          setShowM(false),
          setShowM(false)
            ? (document.body.style.overflow = "auto")
            : (document.body.style.overflow = "hidden")
        )
      }
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleReportMeteologicalParam} ref={form}>
          <div className="modal-header">
            <div className="modal-title">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="ds-block username-field mb-3">
                {t("modals.jellyM.reportTitle")}
                <Input
                  value={reportName}
                  type="text"
                  name="reportName"
                  onChange={onChangeReportName}
                  validations={[required]}
                ></Input>
              </label>
              <label className="mb-3 w-100">
                {" "}
                {t("modals.jellyM.dateAndTime")}
                <DatePicker
                  selected={startDate}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  className="form-control"
                  readOnly
                />
              </label>
              <GetLocation
                latitude={latitude}
                longitude={longitude}
                setLat={setLat}
                setLng={setLng}
              />
              <div class="alert alert-warning" role="alert">
                {t("modals.jellyM.infoGeolocation")}
              </div>
              <label className="ds-block ds-coast-field mb-3">
                {t("modals.jellyM.wind")}
                <Select
                  isSearchable={false}
                  id="jellyselect"
                  defaultValue={{ label: t("modals.jellyM.pleaseSelect") }}
                  name="windTypeId"
                  options={[
                    { value: "1", label: t("modals.jellyM.optionHurricane") },
                    { value: "2", label: t("modals.jellyM.optionStrong") },
                    { value: "3", label: t("modals.jellyM.optionModerate") },
                    { value: "4", label: t("modals.jellyM.optionWeak") },
                    { value: "5", label: t("modals.jellyM.optionNowind") },
                  ]}
                  onChange={onChangeWindType}
                />
              </label>
              {/* more info */}
              <label className="ds-block ds-coast-field mb-3">
                {t("modals.jellyM.wave")}
                <Select
                  isSearchable={false}
                  id="jellyselect"
                  defaultValue={{ label: t("modals.jellyM.pleaseSelect") }}
                  name="seaSwellTypeId"
                  options={[
                    { value: "1", label: t("modals.jellyM.optionStrongW") },
                    { value: "2", label: t("modals.jellyM.optionModerateW") },
                    { value: "3", label: t("modals.jellyM.optionWeakW") },
                    { value: "4", label: t("modals.jellyM.optionNowaves") },
                  ]}
                  onChange={onChangeSeaSwellType}
                />
              </label>
              <label className="ds-block ds-coast-field mb-3">
                {t("modals.jellyM.fog")}
                <Select
                  isSearchable={false}
                  id="jellyselect"
                  defaultValue={{ label: t("modals.jellyM.pleaseSelect") }}
                  name="fogTypeId"
                  options={[
                    {
                      value: "1",
                      label: t("modals.jellyM.optionVeryT"),
                    },
                    {
                      value: "2",
                      label: t("modals.jellyM.optionT"),
                    },
                    {
                      value: "3",
                      label: t("modals.jellyM.optionModerateF"),
                    },
                    {
                      value: "4",
                      label: t("modals.jellyM.optionWeakF"),
                    },
                    { value: "4", label: t("modals.jellyM.optionNofog") },
                  ]}
                  onChange={onChangeFogType}
                />
              </label>
              <label className="ds-block comments-field mb-3">
                {t("modals.jellyM.des")}
                <Input
                  value={comment}
                  type="text"
                  name="comment"
                  onChange={onChangeComment}
                  validations={[required]}
                ></Input>
              </label>
              <p>{t("modals.jellyM.attachImage")}</p>
              <div
                className="file-upload-container"
                onClick={handleClick}
                style={containerStyle}
                onDragOver={onDragOver}
                onDrop={onDrop}
              >
                <input
                  type="file"
                  id="fileInput"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <label
                  htmlFor="fileInput"
                  className="file-upload-label"
                  style={labelStyle}
                >
                  {uploading
                    ? "Loading..."
                    : uploadedFileName
                    ? uploadedFileName
                    : t("modals.dragndrop")}
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {t("modals.sent")}
              </div>
            )}
            {displayError && (
              <div className="alert alert-danger" role="alert">
                {t("modals.fillAll")}
              </div>
            )}
            <button className="sub-report">
              {t("modals.jellyM.submitReport")}
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <button
          className="btn-colse-modal"
          onClick={() => dispatch(setShowM(false))}
        ></button>
      </div>
    </div>
  );
};

export default MeteorologicalParamModal;
