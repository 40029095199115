import React from 'react';
import RegisterForm from './RegisterForm';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();

  return (
    <div className="register-container">
      <div className="register-wrapper">
        {/* <div className="second-waves"></div> */}
        <div className="info-form">
          <p>{t('register.registerInSystem')}</p>
          <p>{t('register.registerInsysDescription')}</p>
        </div>
        <h1>{t('register.signup')}</h1>
        <p className="sub-title">
          {t('register.descriptionSignup.main')}
          <a className="register-accout" href="/login">
            {t('register.descriptionSignup.login')}
          </a>
          {t('register.descriptionSignup.now')}
        </p>
        <div className="form-group">
          <RegisterForm />
        </div>
      </div>
      <div className="card card-info-login">
        <div className="card-body">
          <h5 className="card-title">{t('register.registerInSystem')}</h5>
          <p className="card-text">{t('register.registerInsysDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Register;
