import React from 'react';
import { REPORT_PER_PAGE } from './../utils/constants';
import Atypical from './../rep/Atypical';

// add this
const ReportA = ({ reports, page, handleTableChange }) => {
  //   console.log(REPORT_PER_PAGE);

  const startIndex = (page - 1) * REPORT_PER_PAGE;
  const selectedReports = reports.slice(
    startIndex,
    startIndex + REPORT_PER_PAGE
  );
  return selectedReports.map((reports) => (
    <>
      {/* add this */}
      <Atypical reports={reports} handleTableChange={handleTableChange} />
    </>
  ));
};

export default ReportA;
