// The service uses Axios for HTTP requests and Local Storage for user information.

import axios from "axios";
import configUrl from "../configuration-data.json";
import { setMessage } from "./../actions/message";
import store from "./../store";

const API_URL = configUrl.SERVER_URL + "JellyfishReports";
const API_URL_A = configUrl.SERVER_URL + "AtypicalActivities";
const API_URL_B = configUrl.SERVER_URL + "BaseReports";
const API_URL_M = configUrl.SERVER_URL + "MeteorologicalParameters";
const API_URL_P = configUrl.SERVER_URL + "PollutantsOfTheSeaAndCoastline";

const user = JSON.parse(localStorage.getItem("user"));

const jellyfish = (
  quantity,
  species,
  //   baseReport: {
  userId,
  reportName,
  reportDate,
  latitude,
  longitude,
  comment,
  reportPhotoString
  //   }
) => {
  return axios
    .post(
      API_URL + "/CreateReport",
      {
        quantity,
        species,
        baseReport: {
          userId: user.id,
          reportName,
          reportDate,
          latitude,
          longitude,
          comment,
          reportPhotoString,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    )
    .then((res) => {
      store.dispatch(setMessage("success"));
      // console.log('RESPONSE RECEIVED: ', res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
};

const atypical = (
  stormTypeId,
  //   baseReport: {
  userId,
  reportName,
  reportDate,
  latitude,
  longitude,
  comment,
  reportPhotoString
  //   }
) => {
  return axios
    .post(
      API_URL_A + "/CreateReport",
      {
        stormTypeId,
        baseReport: {
          userId: user.id,
          reportName,
          reportDate,
          latitude,
          longitude,
          comment,
          reportPhotoString,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    )
    .then((res) => {
      // console.log('RESPONSE RECEIVED: ', res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
};

const baseReport = (
  //   baseReport: {
  userId,
  reportName,
  reportDate,
  latitude,
  longitude,
  comment,
  reportPhotoString
  //   }
) => {
  return axios
    .post(
      API_URL_B + "/CreateReport",
      {
        // baseReport: {
        userId: user.id,
        reportName,
        reportDate,
        latitude,
        longitude,
        comment,
        reportPhotoString,
        // },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    )
    .then((res) => {
      // console.log('RESPONSE RECEIVED: ', res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
};

const meteorologicalParam = (
  //   baseReport: {
  fogTypeId,
  seaSwellTypeId,
  windTypeId,
  userId: user.id,
  reportName,
  reportDate,
  latitude,
  longitude,
  comment,
  reportPhotoString
  //   }
) => {
  return axios
    .post(
      API_URL_M + "/CreateReport",
      {
        fogTypeId,
        seaSwellTypeId,
        windTypeId,
        baseReport: {
          userId: user.id,
          reportName,
          reportDate,
          latitude,
          longitude,
          comment,
          reportPhotoString,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    )
    .then((res) => {
      // console.log('RESPONSE RECEIVED: ', res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
};

const pollutants = (
  //   baseReport: {
  oilPolution,
  biologicalPolution,
  bigFloatingObjects,
  plasticPolution,
  otherPolution,
  userId: user.id,
  reportName,
  reportDate,
  latitude,
  longitude,
  comment,
  reportPhotoString
  //   }
) => {
  return axios
    .post(
      API_URL_P + "/CreateReport",
      {
        oilPolution,
        biologicalPolution,
        bigFloatingObjects,
        plasticPolution,
        otherPolution,
        baseReport: {
          userId: user.id,
          reportName,
          reportDate,
          latitude,
          longitude,
          comment,
          reportPhotoString,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    )
    .then((res) => {
      // console.log('RESPONSE RECEIVED: ', res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
};

export default {
  jellyfish,
  atypical,
  baseReport,
  meteorologicalParam,
  pollutants,
};
