import { SET_SHOWJ, SET_SHOWA, SET_SHOWM, SET_SHOWP, SET_SHOWO } from './types';

export const setShowJ = (state) => ({
  type: SET_SHOWJ,
  payload: state,
});

export const setShowA = (state) => ({
  type: SET_SHOWA,
  payload: state,
});

export const setShowM = (state) => ({
  type: SET_SHOWM,
  payload: state,
});

export const setShowP = (state) => ({
  type: SET_SHOWP,
  payload: state,
});

export const setShowO = (state) => ({
  type: SET_SHOWO,
  payload: state,
});
