import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckButton from 'react-validation/build/button';
import DatePicker from 'react-datepicker';
import Form from 'react-validation/build/form';
import GetLocation from './GetLocation';
import Input from 'react-validation/build/input';
import Resizer from 'react-image-file-resizer';
import { pollutants } from '../../../actions/auth';
import { setShowP } from './../../../actions/modalActions';
import { useTranslation } from 'react-i18next';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const PollutantstModal = ({
  handleReportsUpdated,
  title,
  show,
  handleClose,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().setHours(new Date().getHours(), new Date().getMinutes())
    )
  );
  const form = useRef();
  const checkBtn = useRef();

  const dispatch = useDispatch();
  const [reportName, setreportName] = useState('');
  const [userId, setuserId] = useState('');
  const [comment, setComment] = useState('');
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);

  const { t } = useTranslation();

  const [reportPhotoString, setBaseImage] = useState([]);
  const [fileInput, setFileInput] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploading, setUploading] = useState(false);

  const inputRef = useRef();

  const [oilPolution, setChecked] = React.useState(false);
  const [biologicalPolution, setCheckedB] = React.useState(false);
  const [plasticPolution, setCheckedPP] = React.useState(false);

  const [bigFloatingObjects, setbigFloatingObjects] = useState('');

  const { user: currentUser } = useSelector((state) => state.auth);

  const containerStyle = {
    width: '100%',
    height: '150px',
    border: '2px dashed #007BFF',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  };

  const labelStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#007BFF',
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  if (!show) {
    return null;
  }

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile();
        handleFile(file);
      }
    } else {
      const file = e.dataTransfer.files[0];
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    setUploading(true);
    setFileInput(true);
    setUploadedFileName(file.name);
    try {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        'base64',
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleChangeOilP = () => {
    setChecked(!oilPolution);
  };

  const handleChangeOilB = () => {
    setCheckedB(!biologicalPolution);
  };

  const handleChangeOilPP = () => {
    setCheckedPP(!plasticPolution);
  };

  const onChangeReportName = (e) => {
    const reportName = e.target.value;
    setreportName(reportName);
  };

  const onChangeUserId = (e) => {
    const userId = e.target.value;
    setuserId(userId);
  };

  const onChangeComment = (e) => {
    const comment = e.target.value;
    setComment(comment);
  };

  const onChangebigFloatingObjects = (e) => {
    const bigFloatingObjects = e.target.value;
    setbigFloatingObjects(bigFloatingObjects);
  };

  const handleReportPollutants = (e) => {
    e.preventDefault();

    form.current.validateAll();

    dispatch(setShowP(false));

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        pollutants(
          oilPolution,
          biologicalPolution,
          bigFloatingObjects,
          plasticPolution,
          '',
          userId,
          reportName,
          startDate,
          latitude,
          longitude,
          comment,
          reportPhotoString || ''
        )
      )
        .then(() => {
          handleReportsUpdated(true);
          setFileInput(false);
        })
        .catch(() => {
          handleReportsUpdated(true);
        });
    }
  };

  return (
    <div
      className="modal-container"
      onClick={() =>
        dispatch(
          setShowP(false),
          setShowP(false)
            ? (document.body.style.overflow = 'auto')
            : (document.body.style.overflow = 'hidden')
        )
      }
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleReportPollutants} ref={form}>
          <div className="modal-header">
            <div className="modal-title">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="ds-block username-field mb-3">
                {t('modals.jellyM.reportTitle')}
                <Input
                  type="text"
                  name="reportName"
                  onChange={onChangeReportName}
                  validations={[required]}
                ></Input>
              </label>
              <label className="mb-3 w-100">
                {t('modals.jellyM.dateAndTime')}
                <DatePicker
                  name="reportDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  readOnly
                />
              </label>
              <GetLocation
                latitude={latitude}
                longitude={longitude}
                setLat={setLat}
                setLng={setLng}
              />
              <div class="alert alert-warning" role="alert">
                {t('modals.jellyM.infoGeolocation')}
              </div>
              <p>{t('modals.jellyM.typeContamination')}</p>
              <div class="ds-flex my-3 ds-flex-col-xs">
                <label className="ds-flex mb-3 mr-3 checkboxP">
                  <input
                    className="mr-3"
                    name="oilPolution"
                    type="checkbox"
                    checked={oilPolution}
                    onChange={handleChangeOilP}
                  />
                  {t('modals.jellyM.oilPolution')}
                </label>
                <label className="ds-flex mb-3 mr-3 checkboxP">
                  <input
                    className="mr-3"
                    name="biologicalPolution"
                    type="checkbox"
                    checked={biologicalPolution}
                    onChange={handleChangeOilB}
                  />
                  {t('modals.jellyM.bioPolution')}
                </label>
                <label className="ds-flex mb-3 mr-3 checkboxP">
                  <input
                    className="mr-3"
                    name="biologicalPolution"
                    type="checkbox"
                    checked={plasticPolution}
                    onChange={handleChangeOilPP}
                  />
                  {t('modals.jellyM.plasticPolution')}
                </label>
              </div>
              <label className="ds-block username-field mb-3">
                {t('modals.jellyM.bigfloatingpollution')}
                <Input
                  type="text"
                  name="bigFloatingObjects"
                  onChange={onChangebigFloatingObjects}
                />
              </label>
              <label className="ds-block username-field mb-3">
                {t('modals.jellyM.commentP')}
                <Input
                  type="text"
                  name="comment"
                  onChange={onChangeComment}
                  validations={[required]}
                />
              </label>
              {/* <UploadAndDisplayImage /> */}
              <p>{t('modals.jellyM.attachImage')}</p>
              <div
                className="file-upload-container"
                onClick={() => inputRef.current.click()}
                style={containerStyle}
                onDragOver={onDragOver}
                onDrop={onDrop}
              >
                <input
                  type="file"
                  id="fileInput"
                  onChange={onFileChange}
                  style={{ display: 'none' }}
                  ref={inputRef}
                />
                <label
                  htmlFor="fileInput"
                  className="file-upload-label"
                  style={labelStyle}
                >
                  {uploading
                    ? 'Loading...'
                    : uploadedFileName
                    ? uploadedFileName
                    : t('modals.dragndrop')}
                </label>
              </div>
              {/* <FileBase64
                multiple={true}
                onDone={(files) => onFileChange(files)}
              /> */}
              <br></br>
              {/* <img src={`${reportPhotoString[0]?.base64}`} height="200px" /> */}
            </div>
          </div>
          <div className="modal-footer">
            {/* <button className="sub-report">Submit Report</button> */}
            {fileInput ? (
              <button className="sub-report">
                {t('modals.jellyM.submitReport')}
              </button>
            ) : (
              <div className="text-left text-danger">
                {fileInput ? '' : t('modals.jellyM.selectFile')}
              </div>
            )}
          </div>
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
        <button
          className="btn-colse-modal"
          onClick={() => dispatch(setShowP(false))}
        ></button>
      </div>
    </div>
  );
};

export default PollutantstModal;
