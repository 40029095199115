import React, { useState } from 'react';

import Moment from 'react-moment';
import configUrl from '../../../../configuration-data.json';

const Meteorological = ({ reports, handleTableChange }) => {
  const test = JSON.parse(localStorage.getItem('user'));

  const [deleteLabel, setDeleteLabel] = useState('Delete');

  const handleDeleteClick = () => {
    if (deleteLabel !== 'Delete') {
      deletePost();
      setDeleteLabel('Delete');
    }
    setDeleteLabel('Confirm Delete ✓');
  };

  function deletePost(id) {
    fetch(
      configUrl.SERVER_URL +
        `BaseReports/DeleteReport/?baseReportId=${reports.baseReport.id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json: charset=utf-8',
          Authorization: `Bearer ${test.token}`,
        },
      }
    ).then((resp) => {
      //add this
      handleTableChange(true);
    });
  }
  return (
    <div className="col-12 col-sm-6 col-md-4">
      {/* {console.log('tets repo', reports)} */}
      <div className="card card-different--color">
        <img
          className="card-img-top card-image--adminpanel"
          src={reports?.baseReport?.reportPhoto?.photo}
          alt="Card cap"
        />
        <div className="card-body">
          <h5 className="card-title">{reports.baseReport.reportName}</h5>
          <hr />
          <h5 className="card-title">Report information :</h5>
          <p>Report id: {reports.baseReport.id}</p>
          <hr />
          <p>Comment: {reports.baseReport.comment}</p>
          <hr />
          <p>Reporters email: {reports.baseReport.user.email}</p>
          <hr />
          <p>Fog Type: {reports?.fogType?.type}</p>
          <hr />
          <p>Sea Swell Type: {reports?.seaSwellType?.type}</p>
          <hr />
          <p>Wind Type: {reports?.windType?.type}</p>
        </div>
        <div className="card-body card-body--p-0-5 d-flex justify-content-end">
          <button
            class={
              deleteLabel === 'Delete' ? 'btn btn-danger' : 'btn btn-warning'
            }
            onClick={() => handleDeleteClick()}
          >
            {deleteLabel}
          </button>
        </div>
        <Moment
          className="txt-align-r pr-3 py-3 background-color--light-green"
          format="DD-MM-YYYY HH:mm a"
          date={reports.baseReport.reportDate}
          add={{ hours: 2 }}
        />
      </div>
    </div>
  );
};

export default Meteorological;
