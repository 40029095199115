import React from 'react';
import '../Styles/Login.css';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="info-form">
          <p> {t('login.loginInSystem')}</p>
          <p>{t('login.logInsysDescription')}</p>
        </div>
        <h1>{t('login.signin')}</h1>
        <p className="sub-title">
          {t('login.descriptionSignin.main')}
          <a className="register-accout" href="/register">
            {t('login.descriptionSignin.reg')}
          </a>
          {t('login.descriptionSignin.now')}!
        </p>
        <div className="form-group">
          {/* Login Form */}
          <LoginForm />
        </div>
      </div>
      <div className="card card-info-login">
        <div className="card-body">
          <h5 className="card-title">{t('login.loginInSystem')}</h5>
          <p className="card-text">{t('login.logInsysDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
