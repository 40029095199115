import React, { useEffect, useState } from "react";

import Moment from "react-moment";
import { Navigate } from "react-router-dom";
import axios from "axios";
import configUrl from "../../../configuration-data.json";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function GetMyReportsPollutants() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const test = JSON.parse(localStorage.getItem("user"));

  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log(jsonDate);
    axios
      .get(
        configUrl.SERVER_URL + "PollutantsOfTheSeaAndCoastline/GetMyReports",
        {
          // jsonDate,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json: charset=utf-8",
            Authorization: `Bearer ${test.token}`,
          },
        }
      )
      .then((response) => {
        setPosts(response.data);
        // console.log(response.data);
      });
  }, [test.token]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="account-contaner">
      <div className="row">
        {posts.map((repostP) => (
          <div key={repostP.id} className="col-12 col-sm-6 col-md-4">
            <div className="card card-different--color">
              <img
                className="card-img-top card-image--user"
                src={repostP?.baseReport?.reportPhoto?.photo}
                alt="Card cap"
              />
              {/* <div className="card-body">
                <h5 className="card-title">{repostP.baseReport.reportName}</h5>
              </div> */}
              <div className="card-body">
                <span className="sub-title">{t("history.titleCard")}</span>
                <h5 className="card-title">{repostP.baseReport.reportName}</h5>
                <hr />
                <span className="sub-title">{t("history.detailsCard")}</span>
                <h5 className="cart-title-history">
                  <span className="icon icon-biological-pol "></span>
                  {repostP.biologicalPolution ? (
                    <span className="icon icon-red-dot"></span>
                  ) : (
                    <span className="icon icon-green-dot"></span>
                  )}
                </h5>

                <h5 className="cart-title-history">
                  <span className="icon icon-oil"></span>
                  {repostP.oilPolution ? (
                    <span className="icon icon-red-dot"></span>
                  ) : (
                    <span className="icon icon-green-dot"></span>
                  )}
                </h5>

                <h5 className="cart-title-history">
                  <span className="icon icon-stump"></span>
                  {repostP.bigFloatingObjects === "" ? (
                    <span className="icon icon-red-dot"></span>
                  ) : (
                    repostP.bigFloatingObjects
                  )}
                </h5>

                <h5 className="cart-title-history">
                  <span className="icon icon-comments"></span>
                  {repostP.baseReport.comment}
                </h5>
              </div>
              <Moment
                className="txt-align-r pr-3 py-3 background-color--light-green"
                format="DD-MM-YYYY HH:mm"
                date={repostP.baseReport.reportDate}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GetMyReportsPollutants;
