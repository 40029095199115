import "bootstrap/dist/js/bootstrap.js";

import React from "react";
import ReporstAtypical from "./AllReports/ReportAtypical";
import ReporstJellyFish from "./AllReports/ReportJellyFish";
import ReporstOther from "./AllReports/ReportOther";
import ReportsMeteorologicalParam from "./AllReports/ReportMeteorologicalParam";
import ReportsPollu from "./AllReports/ReportPoll";

function NavMenuAdmin() {
  return (
    <div className="admin__container-wrapper">
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="contact-tab"
              data-toggle="tab"
              href="#jellyfishrep"
              role="tab"
              aria-controls="jellyfishrep"
              aria-selected="false"
            >
              Jellyfish Reports
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#atypical"
              role="tab"
              aria-controls="atypical"
              aria-selected="false"
            >
              Atypical Weather Event Reports
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#pollutants"
              role="tab"
              aria-controls="pollutants"
              aria-selected="false"
            >
              Pollutants Reports
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#meteorological"
              role="tab"
              aria-controls="meteorological"
              aria-selected="false"
            >
              Meteorological Reports
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#others"
              role="tab"
              aria-controls="others"
              aria-selected="false"
            >
              Others Reports
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="jellyfishrep"
            role="tabpanel"
            aria-labelledby="jellyfish-tab"
          >
            <ReporstJellyFish />
          </div>
          <div
            className="tab-pane fade"
            id="atypical"
            role="tabpanel"
            aria-labelledby="atypical-tab"
          >
            <ReporstAtypical />
          </div>
          <div
            className="tab-pane fade"
            id="pollutants"
            role="tabpanel"
            aria-labelledby="pollutants-tab"
          >
            {/* <ReportsPollutants /> */}
            <ReportsPollu />
          </div>
          <div
            className="tab-pane fade"
            id="meteorological"
            role="tabpanel"
            aria-labelledby="meteorological-tab"
          >
            <ReportsMeteorologicalParam />
          </div>
          <div
            className="tab-pane fade"
            id="others"
            role="tabpanel"
            aria-labelledby="others-tab"
          >
            <ReporstOther />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenuAdmin;
