// The service uses Axios for HTTP requests and Local Storage for user information.
import axios from 'axios';

import configUrl from '../configuration-data.json';

const API_URL = configUrl.SERVER_URL + 'Users';

const register = (fullName, userName, email, password) => {
  return axios.post(API_URL + '/RegisterUser', {
    fullName,
    userName,
    email,
    password,
  });
};

const login = (userName, password) => {
  return axios
    .post(API_URL + '/Login', {
      userName,
      password,
    })
    .then((response) => {
      // if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data));
      // }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

export default {
  register,
  login,
  logout,
};
