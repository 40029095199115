import { Navigate, useNavigate } from "react-router-dom";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { login } from "../actions/auth";
import { useTranslation } from "react-i18next";

const LoginForm = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("login.fieldsSignIn.requiredFieldError")}
        </div>
      );
    }
  };

  const onChangeUsername = (e) => {
    const userName = e.target.value;
    setUsername(userName);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  if (currentUser) {
    return <Navigate to="/" />;
  }

  // useEffect(() => {
  //   console.log('user test:', currentUser);
  //   if (currentUser) {
  //     navigate('/');
  //   }
  // }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(userName, password))
        .then(() => {
          navigate("/user-profile");
          //   window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/user-profile" />;
  }

  return (
    <Form onSubmit={handleLogin} ref={form}>
      <div className="form-group form-group--login">
        <label htmlFor="userName">{t("login.fieldsSignIn.userName")}</label>
        <Input
          type="text"
          className="form-control"
          name="userName"
          value={userName}
          onChange={onChangeUsername}
          validations={[required]}
        />
        <span className="sub-title-md d-relative">
          <span className="required-ch">*</span>
          {t("login.fieldsSignIn.subtitle")}
        </span>
      </div>

      <div className="form-group password-field">
        <label htmlFor="password">{t("login.fieldsSignIn.password")}</label>
        <Input
          type="password"
          className="form-control"
          name="password"
          value={password}
          onChange={onChangePassword}
          validations={[required]}
        />
        <span className="sub-title-md d-relative">
          <span className="required-ch">*</span>
          {t("login.fieldsSignIn.subtitlepass")}
        </span>
        <a
          className="sub-title float-right font-weight-bold"
          href="/forgotpassword"
        >
          {t("login.fieldsSignIn.forgotPassword")}?
        </a>
      </div>

      <div className="form-group">
        <button className="submit-login-btn" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>{t("login.btnLogin")}</span>
        </button>
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {/* {message} */}
            <p className="mb-0">{t("login.fieldsSignIn.errorMessage")}</p>
          </div>
        </div>
      )}
      <CheckButton style={{ display: "none" }} ref={checkBtn} />
    </Form>
  );
};

export default LoginForm;
