import React from 'react';
import { useTranslation } from 'react-i18next';

const BannerInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        <b>{t('home.catDes')}</b>
      </p>
    </div>
  );
};

export default BannerInfo;
