import "leaflet/dist/leaflet.css";

import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import React, { useEffect, useState } from "react";

import L from "leaflet";

const defaultIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const GetLocation = ({ setLat, setLng }) => {
  const [position, setPosition] = useState(null);
  const [initialLocationSet, setInitialLocationSet] = useState(false);

  const Markers = () => {
    const map = useMapEvents({
      click: (e) => {
        setPosition(e.latlng);
        setLat(e.latlng.lat);
        setLng(e.latlng.lng);
        setInitialLocationSet(true);
      },
    });

    useEffect(() => {
      if (!initialLocationSet && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = position.coords;
            setPosition({
              lat: coords.latitude,
              lng: coords.longitude,
            });
            setLat(coords.latitude);
            setLng(coords.longitude);
            map.flyTo([coords.latitude, coords.longitude], 10);
            setInitialLocationSet(true); // Set the flag to true after setting initial location
          },
          (error) => {
            // Handle error or set default position
          }
        );
      }
    }, [map, initialLocationSet]); // Add initialLocationSet to dependency array

    return position === null ? null : (
      <Marker position={position} icon={defaultIcon}></Marker>
    );
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <MapContainer
        center={[42.733883, 25.48583]}
        zoom={7}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Markers />
      </MapContainer>
    </div>
  );
};

export default GetLocation;
