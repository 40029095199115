import React, { useState } from 'react';

import Moment from 'react-moment';
import configUrl from '../../../../configuration-data.json';

const Other = ({ reports, handleTableChange }) => {
  const test = JSON.parse(localStorage.getItem('user'));

  const [deleteLabel, setDeleteLabel] = useState('Delete');

  const handleDeleteClick = () => {
    if (deleteLabel !== 'Delete') {
      deletePost();
      setDeleteLabel('Delete');
    }
    setDeleteLabel('Confirm Delete ✓');
  };

  function deletePost(id) {
    fetch(
      configUrl.SERVER_URL +
        `BaseReports/DeleteReport/?baseReportId=${reports.id}`,
      // baseReport.id
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json: charset=utf-8',
          Authorization: `Bearer ${test.token}`,
        },
      }
    ).then((resp) => {
      //add this
      handleTableChange(true);
    });
  }

  return (
    <div className="col-12 col-sm-6 col-md-4">
      <div className="card card-different--color">
        <img
          className="card-img-top card-image--adminpanel"
          src={reports?.reportPhoto?.photo}
          alt="Card cap"
        />
        <div className="card-body">
          <h5 className="card-title">{reports.reportName}</h5>
          <hr />
          <h5 className="card-title">Report information :</h5>
          <p>Report id: {reports.id}</p>
          <hr />
          <p>Comment: {reports.comment}</p>
          <hr />
          <p>Reporters email: {reports.user.email}</p>
          <hr />
          <p>Reporters name: {reports.user.fullName}</p>
          <hr />
          <p>Reporters username: {reports.user.userName}</p>
        </div>
        <div className="card-body card-body--p-0-5 d-flex justify-content-end">
          <button
            class={
              deleteLabel === 'Delete' ? 'btn btn-danger' : 'btn btn-warning'
            }
            onClick={() => handleDeleteClick()}
          >
            {deleteLabel}
          </button>
        </div>
        <Moment
          className="txt-align-r pr-3 py-3 background-color--light-green"
          format="DD-MM-YYYY HH:mm a"
          date={reports.reportDate}
          add={{ hours: 2 }}
        />
      </div>
    </div>
  );
};

export default Other;
