import React, { useEffect, useState } from "react";

import atypicalIcon from "../../Assetss/atypical.png";
import axios from "axios";
import configUrl from "../../configuration-data.json";
import jellyfishIcon from "../../Assetss/jellyfish-icon.png";
import meteoIcon from "../../Assetss/meteo-icon.png";
import otherIcon from "../../Assetss/other-icon.png";
import pollutants from "../../Assetss/pollutants.png";
import { useTranslation } from "react-i18next";

// import useGenerateRandomColor from './useGenerateRandomColor';

function AllReportsCounter() {
  const test = JSON.parse(localStorage.getItem("user"));
  const [postsA, setPostsA] = useState([]);
  const [postsJ, setPostsJ] = useState([]);
  const [postsM, setPostsM] = useState([]);
  const [postsO, setPostsO] = useState([]);
  const [postsP, setPostsP] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(configUrl.SERVER_URL + "AtypicalActivities/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPostsA(response.data);
        // console.log(response.data);
      });
    axios
      .get(configUrl.SERVER_URL + "JellyfishReports/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPostsJ(response.data);
        // setProducts(response.data);
        // console.log(response.data);
      });
    axios
      .get(configUrl.SERVER_URL + "MeteorologicalParameters/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPostsM(response.data);
        // console.log(response.data);
      });
    axios
      .get(configUrl.SERVER_URL + "BaseReports/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPostsO(response.data);
        // console.log(response.data);
      });
    axios
      .get(
        configUrl.SERVER_URL + "PollutantsOfTheSeaAndCoastline/GetMyReports",
        {
          // jsonDate,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json: charset=utf-8",
            Authorization: `Bearer ${test.token}`,
          },
        }
      )
      .then((response) => {
        setPostsP(response.data);
        // console.log(response.data);
      });
  }, [test.token]);

  return (
    <div className="user-details-wrapper-r">
      {/* <h3>Reports</h3> */}
      <div className="user-details-reposrt">
        <p className="personal-info-txt">{t("profile.numberofRep.jelly")}</p>
        <img className="map-legend-icon" src={jellyfishIcon} alt="jellyfish" />
        <h5>{postsJ.length}</h5>
      </div>
      {/*  */}
      <div className="user-details-reposrt">
        <p className="personal-info-txt">{t("profile.numberofRep.storm")}</p>
        <img className=" map-legend-icon" src={atypicalIcon} alt="atypical" />
        <h5>{postsA.length}</h5>
      </div>
      {/*  */}
      <div className="user-details-reposrt">
        <p className="personal-info-txt">
          {t("profile.numberofRep.meteorological")}
        </p>
        <img className="map-legend-icon" src={meteoIcon} alt="meteo" />
        <h5>{postsM.length}</h5>
      </div>
      <div className="user-details-reposrt">
        <p className="personal-info-txt">{t("profile.numberofRep.polu")}</p>
        <img className="map-legend-icon" src={pollutants} alt="pollutants" />
        <h5>{postsP.length}</h5>
      </div>
      {/*  */}
      <div className="user-details-reposrt">
        <p className="personal-info-txt">{t("profile.numberofRep.other")}</p>
        <img className="map-legend-icon" src={otherIcon} alt="other" />
        <h5>{postsO.length}</h5>
      </div>
    </div>
  );
}

export default AllReportsCounter;
