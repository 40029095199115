export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// REPORTS

export const JELLYFISH_REPORT_POST_SUCCESS = 'JELLYFISH_REPORT_POST_SUCCESS';
export const JELLYFISH_REPORT_POST_FAIL = 'JELLYFISH_REPORT_POST_FAIL';

export const ATYPICAL_REPORT_POST_SUCCESS = 'ATYPICAL_REPORT_POST_SUCCESS';
export const ATYPICAL_REPORT_POST_FAIL = 'ATYPICAL_REPORT_POST_FAIL';

export const OTHER_REPORT_POST_SUCCESS = 'ATYPICAL_REPORT_POST_SUCCESS';
export const OTHER_REPORT_POST_FAIL = 'ATYPICAL_REPORT_POST_FAIL';

export const METEOROLOGICAL_PARAM_REPORT_POST_SUCCESS =
  'METEOROLOGICAL_PARAM_REPORT_POST_SUCCESS';
export const METEOROLOGICAL_PARAM_REPORT_POST_FAIL =
  'METEOROLOGICAL_PARAM_REPORT_POST_FAIL';

export const POLLUTANTS_REPORT_POST_SUCCESS = 'POLLUTANTS_REPORT_POST_SUCCESS';
export const POLLUTANTS_REPORT_POST_FAIL = 'POLLUTANTS_REPORT_POST_FAIL';

// SOW MODALS
export const SET_SHOWJ = 'SET_SHOWJ';
export const SET_SHOWA = 'SET_SHOWA';
export const SET_SHOWM = 'SET_SHOWM';
export const SET_SHOWP = 'SET_SHOWP';
export const SET_SHOWO = 'SET_SHOWO';
