import React, { useState } from "react";

import Moment from "react-moment";
import configUrl from "../../../../configuration-data.json";

const Pollu = ({ reports, handleTableChange }) => {
  const test = JSON.parse(localStorage.getItem("user"));

  const [deleteLabel, setDeleteLabel] = useState("Delete");

  const handleDeleteClick = () => {
    if (deleteLabel !== "Delete") {
      deletePost();
      setDeleteLabel("Delete");
    }
    setDeleteLabel("Confirm Delete ✓");
  };

  function deletePost(id) {
    fetch(
      configUrl.SERVER_URL +
        `BaseReports/DeleteReport/?baseReportId=${reports.baseReport.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      }
    ).then((resp) => {
      //add this
      handleTableChange(true);
    });
  }
  return (
    <div className="col-12 col-sm-6 col-md-4">
      <div className="card card-different--color">
        <img
          className="card-img-top card-image--adminpanel"
          src={reports?.baseReport?.reportPhoto?.photo}
          alt="Card cap"
        />
        <div className="card-body">
          <h5 className="card-title">{reports.baseReport.reportName}</h5>
          <hr />
          <h5 className="card-title">Report information :</h5>
          <p>Report id: {reports.baseReport.id}</p>
          <hr />
          <p>Comment: {reports.baseReport.comment}</p>
          <hr />
          <p>Reporters email: {reports.baseReport.user.email}</p>
          <hr />
          <p>Big floating objects: {reports.bigFloatingObjects}</p>
          <hr />
          <p>Other polution: {reports.otherPolution}</p>
          <hr />
          <div className="list-group-item text-center">
            {reports.biologicalPolution === true ? (
              <p className="card-text mb-2">Biological Polution: Yes</p>
            ) : (
              <p className="card-text mb-2">Biological Polution: No</p>
            )}
          </div>
          <div className="list-group-item text-center">
            {reports.oilPolution === true ? (
              <p className="card-text mb-2">Oil Polution: Yes</p>
            ) : (
              <p className="card-text mb-2">Oil Polution: No</p>
            )}
          </div>
          <div className="list-group-item text-center">
            {reports.plasticPolution === true ? (
              <p className="card-text mb-2">Plastic Polution: Yes</p>
            ) : (
              <p className="card-text mb-2">Plastic Polution: No</p>
            )}
          </div>
          <p></p>
        </div>
        <div className="card-body card-body--p-0-5 d-flex justify-content-end">
          <button
            class={
              deleteLabel === "Delete" ? "btn btn-danger" : "btn btn-warning"
            }
            onClick={() => handleDeleteClick()}
          >
            {deleteLabel}
          </button>
        </div>
        <Moment
          className="txt-align-r pr-3 py-3 background-color--light-green"
          format="DD-MM-YYYY HH:mm a"
          date={reports.baseReport.reportDate}
          add={{ hours: 3 }}
        />
      </div>
    </div>
  );
};

export default Pollu;
