import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail } from 'validator';
import { useNavigate, Navigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { setMessage } from '../actions/message';

import { register, login } from '../actions/auth';

const Register = () => {
  const { t } = useTranslation();
  const form = useRef();
  const checkBtn = useRef();

  const { user: currentUser } = useSelector((state) => state.auth);

  let navigate = useNavigate();

  const [fullName, setfullName] = useState('');
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeFullName = (e) => {
    const fullName = e.target.value;
    setfullName(fullName);
  };

  const onChangeUsername = (e) => {
    const userName = e.target.value;
    setUsername(userName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  if (currentUser) {
    return <Navigate to="/successful-registration" />;
  }

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t('register.fieldsSignUp.requiredFieldError')}
        </div>
      );
    }
  };

  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t('register.fieldsSignUp.errorMsgEmail')}
        </div>
      );
    }
  };

  const vfullname = (value) => {
    const fullnameRegex = /^[A-Z][a-zA-Z]+\s[A-Z][a-zA-Z]+$/;

    if (!fullnameRegex.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t('register.fieldsSignUp.errorMsgName')}
        </div>
      );
    }
  };

  const vusername = (value) => {
    if (value.length < 4 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          {t('register.fieldsSignUp.errorMsgUserName')}
        </div>
      );
    }
  };

  const vpassword = (value) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
    if (!passwordRegex.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t('register.fieldsSignUp.errorMsgPass')}
        </div>
      );
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(fullName, userName, email, password))
        .then(() => {
          setSuccessful(true);
          dispatch(setMessage('BRAO'));
          dispatch(login(userName, password))
            .then(() => {
              navigate('/successful-registration');
              //   window.location.reload();
            })
            .catch(() => {
              // setLoading(false);
              setSuccessful(false);
            });
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
    <div>
      <div>
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="fullName">
                  {t('register.fieldsSignUp.fullName')}
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="fullName"
                  value={fullName}
                  onChange={onChangeFullName}
                  validations={[required, vfullname]}
                />
                <span className="sub-title-md d-relative">
                  <span className="required-ch">*</span>
                  {t('register.fieldsSignUp.subtitleFN')}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="userName">
                  {t('register.fieldsSignUp.username')}
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="userName"
                  value={userName}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
                <span className="sub-title-md d-relative">
                  <span className="required-ch">*</span>
                  {t('register.fieldsSignUp.subtitleUserName')}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  {t('register.fieldsSignUp.email')}
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
                <span className="sub-title-md d-relative">
                  <span className="required-ch">*</span>{' '}
                  {t('register.fieldsSignUp.subtitleEmail')}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  {t('register.fieldsSignUp.password')}
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
                <span className="sub-title-md d-relative">
                  <span className="required-ch">*</span>
                  {t('register.fieldsSignUp.subtitlePass')}
                </span>
              </div>

              <div className="form-group">
                <button className="submit-register-btn">
                  {t('register.btnSignUp')}
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? 'alert alert-success' : 'alert alert-danger'
                }
                role="alert"
              >
                {/* {message} */}
                {successful ? message : t('register.fieldsSignUp.errorMsgReg')}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
