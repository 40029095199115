import React, { useEffect, useState } from "react";

import AllReportsCounter from "./AllRepCounter";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Profile() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const str = currentUser.fullName;
  const firstChar = str.charAt(0);

  const { t } = useTranslation();

  const [color, setColor] = useState("");

  useEffect(() => {
    const generateColor = () => {
      setColor(Math.random().toString(16).substr(-6));
    };
    generateColor();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="account-main-content">
      <div className="il__profile-content__container">
        <div className="account-wapper-content">
          <div className="col-account profile-info">
            <p className="personal-info-txt">
              {t("profile.personalInfo.title")}
            </p>
            <div
              className="element-letter-user"
              style={{
                backgroundColor: "#" + color,
              }}
            >
              {firstChar}
            </div>

            <h2> {currentUser.fullName}</h2>
            <div className="user-details-wrapper">
              <div className="user-details">
                <p>{t("profile.personalInfo.userName")}</p>
                <h2> {currentUser.userName}</h2>
              </div>
              <div className="user-details">
                <p>{t("profile.personalInfo.emailAddress")}</p>
                <h2>{currentUser.email}</h2>
              </div>
            </div>
          </div>
          <div className="col-account">
            <p className="personal-info-txt">
              {t("profile.numberofRep.title")}
            </p>
            <AllReportsCounter />
          </div>
          <div className="col-account">
            <div className="alert alert-warning mb-0">
              <strong>{t("profile.personalInfo.inf")}</strong>
              {t("profile.personalInfo.info")}
              <a href="/">
                <strong> {t("profile.personalInfo.home")} </strong>
              </a>
              {t("profile.personalInfo.page")}
            </div>
          </div>
          <div className="col-account"></div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
