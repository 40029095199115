import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import modalReducer from './modalReducer';
import reportsReduser from './AllReports/reports-reduser';

export default combineReducers({
  auth,
  message,
  modalReducer,
  reportsReduser,
});
