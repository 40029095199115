import React, { useEffect, useState } from "react";

import Moment from "react-moment";
import { Navigate } from "react-router-dom";
import axios from "axios";
import configUrl from "../../../configuration-data.json";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function GetMyReportsAtypical() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const test = JSON.parse(localStorage.getItem("user"));

  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log(jsonDate);
    axios
      .get(configUrl.SERVER_URL + "AtypicalActivities/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPosts(response.data);
        // console.log('atypical', response.data);
      });
  }, [test.token]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="account-contaner">
      <div className="row">
        {posts.map((repostA) => (
          <div key={repostA.id} className="col-12 col-sm-6 col-md-4">
            <div className="card card-different--color">
              <img
                className="card-img-top card-image--user "
                src={repostA?.baseReport?.reportPhoto?.photo}
                alt="Card cap"
              />
              {/* <div className="card-body">
                <h5 className="card-title">{repostA.baseReport.reportName}</h5>
              </div> */}
              <div className="card-body">
                <span className="sub-title">{t("history.titleCard")}</span>
                <h5 className="card-title"> {repostA.baseReport.reportName}</h5>
                <hr />
                <span className="sub-title">{t("history.detailsCard")}</span>
                <h5 className="cart-title-history">
                  <span className="icon icon-strom"></span>
                  {repostA.stormType.type}
                </h5>
                <h5 className="cart-title-history">
                  <span className="icon icon-comments"></span>
                  {repostA.baseReport.comment}
                </h5>
              </div>

              <Moment
                className="txt-align-r pr-3 py-3 background-color--light-green"
                format="DD/MM/YYYY HH:mm"
                date={repostA.baseReport.reportDate}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GetMyReportsAtypical;
