import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import ChangePasswrod from './components/ChangePassword';
import ForgotPassword from './components/ForgotPassword';
import { MapPage } from './components/map-page/MapPage';
import AccountUser from './components/accounts/AccountUser';
import BoardAdmin from './components/accounts/BoardAdmin';
import AccountSuccessCreated from './components/accounts/AccountSuccessCreated';
import History from './components/accounts/History';
import Contact from './components/info-pages/Contact';
import AboutUs from './components/info-pages/AboutUs';
import { Terms } from './components/info-pages/Terms-of-use';
import Privacy from './components/info-pages/Privacy-policy';

// import { FetchData } from './components/FetchData';
// import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
// import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
// import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css';
import './Styles/global/il_branding.css';

const App = () => {
  return (
    <Suspense fallback={null}>
      <Layout>
        {/* <BrowserRouter> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/change-password" element={<ChangePasswrod />} />
          <Route exact path="/map" element={<MapPage />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/user-profile" element={<AccountUser />} />
          <Route exact path="/admin" element={<BoardAdmin />} />
          <Route
            exact
            path="/successful-registration"
            element={<AccountSuccessCreated />}
          />
          <Route exact path="/user-history" element={<History />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/terms-of-use" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
        </Routes>
        {/* </BrowserRouter> */}
        {/* <AuthorizeRoute path='/fetch-data' component={FetchData} /> */}
        {/* <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} /> */}
      </Layout>
    </Suspense>
  );
};

export default App;
