import 'leaflet/dist/leaflet.css';

import {
  MapContainer as LeafletMap,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import React, { useEffect, useState } from 'react';

import L from 'leaflet';
import Moment from 'react-moment';
import atypicalIconSrc from '../../Assetss/atypical.png';
import axios from 'axios';
import configUrl from '../../configuration-data.json';
import jellyfishIconSrc from '../../Assetss/jellyfish-icon.png';
import meteoIconSrc from '../../Assetss/meteo-icon.png';
import otherIconSrc from '../../Assetss/other-icon.png';
import pollutantsSrc from '../../Assetss/pollutants.png';
import { useTranslation } from 'react-i18next';
const containerStyle = {
  width: '100%',
  height: '600px',
  zIndex: 1,
};

const iconSize = [25, 41];

const defaultIcon = L.icon({ iconUrl: otherIconSrc, iconSize });

const icons = {
  jelly: L.icon({ iconUrl: jellyfishIconSrc, iconSize }),
  atypical: L.icon({ iconUrl: atypicalIconSrc, iconSize }),
  meteorological: L.icon({ iconUrl: meteoIconSrc, iconSize }),
  other: L.icon({ iconUrl: otherIconSrc, iconSize }),
  pollutants: L.icon({ iconUrl: pollutantsSrc, iconSize }),
};

const reportTypes = [
  { type: 'base', endpoint: 'BaseReports/GetReportsRange' },
  { type: 'jelly', endpoint: 'JellyfishReports/GetReportsRange' },
  { type: 'atypical', endpoint: 'AtypicalActivities/GetReportsRange' },
  {
    type: 'meteorological',
    endpoint: 'MeteorologicalParameters/GetReportsRange',
  },
  {
    type: 'pollutants',
    endpoint: 'PollutantsOfTheSeaAndCoastline/GetReportsRange',
  },
];

const Map = (dataChanged) => {
  console.log('dataChanged', dataChanged);
  const [location, setLocation] = useState({
    lat: 42.7249925,
    lng: 25.4833039,
  });
  const [reports, setReports] = useState({});
  const [filterType, setFilterType] = useState('all');
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserLocation = async () => {
      if (!navigator.geolocation) {
        alert('Geolocation is not supported by this browser.');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
      fetchReports();
    };

    const fetchReports = async () => {
      try {
        const responses = await Promise.all(
          reportTypes.map(({ endpoint }) =>
            axios.get(configUrl.SERVER_URL + endpoint, {
              params: {
                from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                to: new Date(),
              },
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
              },
            })
          )
        );

        const newReports = {};
        reportTypes.forEach(
          ({ type }, index) => (newReports[type] = responses[index].data)
        );
        setReports(newReports);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserLocation();
  }, [dataChanged]);

  if (isLoading || !location.lat || !location.lng) return <div>Loading...</div>;

  return (
    <div className="map-box" id="googleMap">
      <div className="map-filters-buttons">
        <div
          className="ds-flex align-items-cente justify-content-between filters-button"
          role="alert"
        >
          <button
            onClick={() => setFilterType('jelly')}
            className={filterType === 'jelly' ? 'active-filter' : ''}
          >
            {t('filters.jelly')}
          </button>
          <button
            onClick={() => setFilterType('atypical')}
            className={filterType === 'atypical' ? 'active-filter' : ''}
          >
            {t('filters.storm')}
          </button>
          <button
            onClick={() => setFilterType('meteorological')}
            className={filterType === 'meteorological' ? 'active-filter' : ''}
          >
            {t('filters.meteorological')}
          </button>
          <button
            onClick={() => setFilterType('base')}
            className={filterType === 'base' ? 'active-filter' : ''}
          >
            {t('filters.other')}
          </button>
          <button
            onClick={() => setFilterType('pollutants')}
            className={filterType === 'pollutants' ? 'active-filter' : ''}
          >
            {t('filters.pollutants')}
          </button>
          <button
            onClick={() => setFilterType('all')}
            className={filterType === 'all' ? 'active-filter' : ''}
          >
            {t('filters.all')}
          </button>
        </div>
      </div>
      <div className="map-box-wprapper">
        {!isLoading && (
          <LeafletMap
            center={[location.lat, location.lng]}
            zoom={7}
            style={containerStyle}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {reportTypes.map(({ type }) => {
              if (filterType === 'all' || filterType === type) {
                return reports[type].map((report) => (
                  <Marker
                    key={report.id || report.baseReport.id}
                    position={[
                      report.latitude || report.baseReport.latitude,
                      report.longitude || report.baseReport.longitude,
                    ]}
                    icon={icons[type] || defaultIcon}
                  >
                    <Popup>
                      <div className="card card-map" style={{ width: '18rem' }}>
                        <img
                          className="card-img-top card-single-marker-img"
                          src={
                            report?.reportPhoto?.photo ||
                            report?.baseReport?.reportPhoto?.photo
                          }
                          alt="Card cap"
                        />
                        <div className="card-body card-body-map">
                          <h5 className="card-title">
                            {report.reportName || report.baseReport.reportName}
                          </h5>
                          <ul className="list-group list-group-flush">
                            {(report.comment || report.baseReport.comment) && (
                              <div>
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '0.9rem',
                                    margin: '0',
                                  }}
                                >
                                  {t('map.repoertsCard.comments')}
                                </p>
                                <p
                                  style={{
                                    textAlign: 'center',
                                    margin: '5px',
                                  }}
                                >
                                  {report.comment || report.baseReport.comment}
                                </p>
                              </div>
                            )}
                            <div>
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  fontSize: '0.9rem',
                                  margin: '0',
                                }}
                              >
                                {t('map.coordinates')}
                              </p>
                              <p
                                style={{
                                  textAlign: 'center',
                                  margin: '10px',
                                }}
                              >
                                {report.latitude || report.baseReport.latitude}{' '}
                                ,{' '}
                                {report.longitude ||
                                  report.baseReport.longitude}
                              </p>
                            </div>
                          </ul>
                        </div>
                        <Moment
                          className="txt-align-r p-3 background-color--light-green"
                          format="DD.MM.YYYY HH:mm"
                          date={
                            report.reportDate || report.baseReport.reportDate
                          }
                          add={{ hours: 2 }}
                        />
                      </div>
                    </Popup>
                  </Marker>
                ));
              }
              return null;
            })}
          </LeafletMap>
        )}
      </div>
      <div className="card">
        <div className="card-header">{t('map.mapTitle')}</div>
        <div className="map-legend-container">
          <div className="map-legend-item">
            <img
              className="map-legend-icon"
              src={jellyfishIconSrc}
              alt="jellyfish"
            />
            <h5>{t('map.jelly')}</h5>
          </div>
          <div className="map-legend-item">
            <img
              className="map-legend-icon"
              src={atypicalIconSrc}
              alt="atypical"
            />
            <h5>{t('map.storm')}</h5>
          </div>
          <div className="map-legend-item">
            <img className="map-legend-icon" src={meteoIconSrc} alt="meteo" />
            <h5>{t('map.meteorological')}</h5>
          </div>
          <div className="map-legend-item">
            <img
              className="map-legend-icon"
              src={pollutantsSrc}
              alt="pollutants"
            />
            <h5>{t('map.pollutants')}</h5>
          </div>
          <div className="map-legend-item">
            <img className="map-legend-icon" src={otherIconSrc} alt="other" />
            <h5>{t('map.other')}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
