import {
  SET_SHOWJ,
  SET_SHOWA,
  SET_SHOWP,
  SET_SHOWO,
  SET_SHOWM,
} from './../actions/types';

const initialState = {
  showJ: false,
  showA: false,
  showM: false,
  showP: false,
  showO: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SHOWJ:
      return { ...state, showJ: payload };
    case SET_SHOWA:
      return { ...state, showA: payload };
    case SET_SHOWM:
      return { ...state, showM: payload };
    case SET_SHOWP:
      return { ...state, showP: payload };
    case SET_SHOWO:
      return { ...state, showO: payload };
    default:
      return state;
  }
}
