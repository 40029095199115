import "../../Styles/admin-panel/admin-account.css";

import NavMenuAdmin from "./NavMenuAdmin";
import { Navigate } from "react-router-dom";
import React from "react";
import jwt from "jwt-decode";
import { useSelector } from "react-redux";

// import fetchDataJellyfish from './../../actions/getMethod/fetchData-action';

function BoardAdmin() {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/" />;
  }
  const usrTT = currentUser;
  const usrType = usrTT || {};
  let decodeUserT = jwt(usrType.token);
  let usTypeT = decodeUserT || {};
  if (!usTypeT.role.includes("Admin")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="admin-container">
      <div className="admin__container-wrapper">
        <h3>Admin Panel</h3>
        <NavMenuAdmin />
      </div>
    </div>
  );
}

export default BoardAdmin;
