import "../.././Styles/inform-pages/AboutIUs.css";

import React from "react";
import quote from "../../Assetss/double-quote.png";
import seaV from "../../Assetss/sea-v.jpg";
import stormM from "../../Assetss/stormM.jpg";
import strash from "../../Assetss/trashfull.jpg";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="about-us-page mt-4">
      <h1>{t("aboutUs.title")}</h1>
      <div className="row about-us-row">
        <div className="col-8 col-images">
          <div className="main-img">
            <img className="sea-v" src={seaV} alt="sea" />
          </div>
          <div className="sea-other-images">
            <img className="storm-m" src={stormM} alt="storm" />
            <img className="trash-f" src={strash} alt="trash" />
          </div>
        </div>
        <div className="col-4 col-our-mission">
          <div className="our-mission-elements">
            <h2>{t("aboutUs.mission")}</h2>
            <div className="out-mission-box">
              <img className="quote-position" src={quote} alt="quote" />
              <p>{t("aboutUs.welcome")}</p>
              <p>{t("aboutUs.ourCommunity")}</p>
              <p>{t("aboutUs.weBelieve")}</p>
              <p>{t("aboutUs.ourCoismade")}</p>
              <p>{t("aboutUs.thankyou")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
