import "../Styles/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Styles/Home.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BannerInfo from "./BannerInfo";
import Map from "./map-page/Map";
import MapForm from "./map-page/MapForm";
import Modal from "./modals/modal-info-reports";
import Slider from "react-slick";
import atypicalIcon from "../Assetss/atypical.png";
import axios from "axios";
import { clearMessage } from "../actions/message";
import configUrl from "../configuration-data.json";
import jellyfish from "../Assetss/jellyfish.jpg";
import jellyfishIcon from "../Assetss/jellyfish-icon.png";
import meteoIcon from "../Assetss/meteo-icon.png";
import oceanPollution from "../Assetss/ocean-pollution.jpg";
import other from "../Assetss/weather-frame.jpg";
import otherIcon from "../Assetss/other-icon.png";
import pollutantsIcon from "../Assetss/pollutants.png";
import storm from "../Assetss/storm1.jpg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import wave from "../Assetss/wave.jpeg";

const Home = () => {
  const { t } = useTranslation();

  const [reportsUpdated, setReportsUpdated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportIcon, setReportIcon] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const [showBanner, setShowBanner] = useState(true);

  const settings = {
    dots: true, // Показване на точки за навигация
    infinite: true, // Безкраен карусел
    speed: 800, // Скорост на промяна на слайдовете
    slidesToShow: 1, // Брой видими слайдове
    slidesToScroll: 1, // Брой слайдове, които се сменят на всяка промяна
    autoplay: true,
    autoplaySpeed: 10000,
  };

  let location = useLocation();

  // useEffect(() => {
  //   // Скрийте банера след 5 секунди от момента на зареждане на компонента
  //   const timer = setTimeout(() => {
  //     setShowBanner(false);
  //   }, 10000);

  //   // Очистете таймера при размонтиране на компонента
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  useEffect(() => {
    const rootsE = ["/login", "/register"];
    const testE = rootsE || "";
    if (testE.includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const handleReportsUpdated = (value) => {
    setReportsUpdated(reportsUpdated ? false : true);
    scrollToElement("googleMap");
  };

  const scrollToElement = (elementId) => {
    let element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchReports = (type) => {
    let url = "";
    switch (type) {
      case "jelly":
        url = "JellyfishReports/GetReportsRange";
        setReportIcon(jellyfishIcon);
        break;
      case "storm":
        url = "AtypicalActivities/GetReportsRange";
        setReportIcon(atypicalIcon);
        break;
      case "poll":
        url = "PollutantsOfTheSeaAndCoastline/GetReportsRange";
        setReportIcon(pollutantsIcon);
        break;
      case "meteo":
        url = "MeteorologicalParameters/GetReportsRange";
        setReportIcon(meteoIcon);
        break;
      case "other":
        url = "BaseReports/GetReportsRange";
        setReportIcon(otherIcon);
        break;
      default:
        url = "JellyfishReports/GetReportsRange";
        setReportIcon(jellyfishIcon);
        break;
    }

    axios
      .get(configUrl.SERVER_URL + `${url}`, {
        params: {
          from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
          to: new Date(),
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
        },
      })
      .then((response) => {
        setReportData(response.data);
      });
  };

  const handleOpenModal = (type) => {
    fetchReports(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* <Weather /> */}
      <div className="home-page">
        {currentUser ? (
          // if loged in
          // < className="home-page">
          <div>
            {/* <div className="d-flex"> */}

            <div>
              <div className="welcome-banner">
                <div className="square-dark-m"></div>
                <div className="square-light-m"></div>
                <div className="square-dark-right-m"></div>
                <div className="square-light-right-m"></div>
                <div className="square-dark-bottom-m"></div>
                <div className="square-light-bottm-m"></div>
                <div className="welcome-wrapper">
                  <h1>
                    {t("home.home")} {currentUser.fullName},
                  </h1>
                  <div className="slider-box">
                    <p>
                      {t("home.welcomeTextMember")}
                      <br />
                      {t("home.welcomeText1Member")}
                    </p>
                  </div>
                </div>
              </div>
              <h2 className="mb-3">{t("home.gategoriesTitle")}</h2>
            </div>
            {/* // </div> */}
            {/* </div> */}
            <MapForm handleReportsUpdated={handleReportsUpdated} />
          </div>
        ) : (
          // if not
          // <div className="home-page">
          <>
            <div className="welcome-banner">
              <div className="square-dark-m"></div>
              <div className="square-light-m"></div>
              <div className="square-dark-right-m"></div>
              <div className="square-light-right-m"></div>
              <div className="square-dark-bottom-m"></div>
              <div className="square-light-bottm-m"></div>
              <div className="welcome-wrapper">
                <h1> {t("home.homeGuest")}</h1>
                {/* <p>{t('home.welcomeTextGuest')}</p>
                <p>{t('home.wlText1G')}</p>
                <p>{t('home.wlText2G')}</p>
                <p>{t('home.wlText3G')}</p> */}
                <div className="slider-box">
                  <Slider {...settings}>
                    <div>
                      <p>{t("home.welcomeTextGuest")}</p>
                    </div>
                    <div>
                      <p>{t("home.wlText0G")}</p>
                    </div>
                    <div>
                      <p>{t("home.wlText1G")}</p>
                    </div>
                    <div>
                      <p>{t("home.wlText2G")}</p>
                    </div>
                    <div>
                      <p>{t("home.wlText3G")}</p>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <h2>{t("home.gategoriesTitle")}</h2>
            <div className="container-categories my-3 py-3">
              <div className="categories-wrapper">
                <div
                  id="tooltip-jelluy"
                  onClick={() => handleOpenModal("jelly")}
                  className="category-card"
                >
                  <img src={jellyfish} alt="jellyfish" />
                  <h2 className="category-title">{t("reports.jelly")}</h2>
                </div>
                <div
                  className="category-card"
                  onClick={() => handleOpenModal("storm")}
                >
                  <img src={storm} alt="storm" />
                  <h2 className="category-title">{t("reports.storm")}</h2>
                </div>
                <div
                  className="category-card"
                  onClick={() => handleOpenModal("meteo")}
                >
                  <img src={wave} alt="wave" />
                  <h2 className="category-title">
                    {t("reports.meteorological")}
                  </h2>
                </div>
                <div
                  className="category-card"
                  onClick={() => handleOpenModal("poll")}
                >
                  <img src={oceanPollution} alt="ocean pollution" />
                  <h2 className="category-title">{t("reports.pollutants")}</h2>
                </div>
                <div
                  className="category-card"
                  onClick={() => handleOpenModal("other")}
                >
                  <img src={other} alt="other" />
                  <h2 className="category-title">{t("reports.other")}</h2>
                </div>
              </div>
              <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                {/* <h2>Заглавие на категория</h2> */}
                <div className="modal-info">
                  <h5>През последните 24 часа са регистрирани</h5>
                  <img
                    className="reports-icons"
                    src={reportIcon}
                    alt="reports"
                  />
                  <h2>{reportData !== null ? reportData.length : 0}</h2>
                </div>
              </Modal>
            </div>
            <div className="banner-info-reports">
              {/* <p className="p-txt--red no-margin"></p> */}
              {/* {console.log(showBanner)} */}
              <BannerInfo />
            </div>
          </>
          // </div>
        )}
      </div>
      <Map reportsUpdated={reportsUpdated} />
    </div>
  );
};
export default Home;
