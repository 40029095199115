import React, { Component } from "react";
import MapForm from "./MapForm";
import Map from "./Map";
import "../../Styles/page-map/Map.css";
import "../../Styles/page-map/modal/Modal.css";

export class MapPage extends Component {
  static displayName = MapPage.name;

  state = {
    dataChanged: false,
  };

  handleDataChange = () => {
    this.setState({ dataChanged: true });
  };

  handleDataFetched = () => {
    this.setState({ dataChanged: false });
  };

  render() {
    return (
      <div className="map-page">
        <div className="map-wprapper">
          <h1>Report of observation</h1>
          <div className="column-form">
            <MapForm onDataAdded={this.handleDataChange} />
          </div>
          <div className="row-map-form">
            <div className="column-map">
              <Map
                dataChanged={this.state.dataChanged}
                onDataFetched={this.handleDataFetched}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
