export const en = {
  footer: {
    titleHost: ' IS HOSTED BY',
    tuVarna: 'Technical University of Varna TUV',
    iliad: '© 2022 - ILIAD',
    icc: 'ICC',
    aboutUs: 'ABOUT US',
    contacts: 'CONTACTS',
    termsofuse: 'TERMS OF USE',
    privacyPolicy: 'PRIVACY POLICY',
  },
  header: {
    login: 'Login',
    signout: 'Sign out',
    profile: 'Profile',
    history: 'History of reports',
    adminBoard: 'Admin Board',
  },
  //home page
  home: {
    home: 'Welcome ',
    homeGuest: 'Dear visitor,',
    welcomeTextGuest:
      'Welcome to ICC s website, belonging to our community dedicated to the preservation of the sea and its resources! We are delighted that you have chosen to visit and perhaps join us in our mission to protect the marine environment and safeguard all the life it sustains.',
    wlText0G:
      'Seas are a vital part of our planet, and it is our responsibility to ensure they are protected for future generations. By working together, we can achieve real success in improving the well-being of the seas, oceans, and their inhabitants.',
    wlText1G:
      'There are so many ways in which you too can get involved and make a positive impact! Think about this... You can simply start by observing and reporting pollution and unusual situations in the coastal area around you. We promise to take care of the rest!',
    wlText2G:
      'Lastly, we are excited to offer you membership in our community and look forward to seeing you become a part of us!',
    wlText3G:
      'Thank you in advance for your dedication and personal commitment to our cause of protecting the sea!',
    welcomeTextMember:
      'We are so glad that you have joined us in our mission to protect and preserve the sea environment.',
    welcomeText1Member: 'Start your contribution by posting a report!',
    gategoriesTitle: 'Featured Sightings',
    catDes: 'Publishing a report is only possible with an account!',
  },
  // categories
  reports: {
    countReports: 'Number of reports in the last 24 hours: ',
    jelly: 'Jellyfish',
    storm: 'Storm',
    meteorological: 'Meteorological conditions',
    pollutants: 'Pollutants of the sea and the coastline',
    other: 'Other problems',
    subtitle:
      'If you notice anything unusual related to other problems please let us know.',
    subtitleJ:
      'Here you can report your observations regarding the presence and concentration of jellyfish.',
    subtitleA:
      'Here you can report your observations related to storms and storm activity.',
    subtitleM:
      'Here you can report your observations related to wind direction and speed, presence of fog, rough seas, etc.',
    subtitleP:
      'Here you can report your observations related to the ascertaining of different types of pollution located in the sea water and the coastal area.',
    subtitleO:
      'Here you can report issues related to the marine environment that do not fall into the other categories.',
    createRbtn: 'Create report',
  },
  // filters
  filters: {
    filtersTitle: 'Filters:',
    jelly: 'Jellyfish',
    storm: 'Storm',
    meteorological: 'Meteorological',
    pollutants: 'Pollutants',
    other: 'Other',
    all: 'All',
  },
  // map legend
  map: {
    mapTitle: 'Map legend',
    coordinates: 'Coordinates:',
    jelly: 'Jellyfish',
    storm: 'Storm events',
    meteorological: 'Meteorological conditions',
    pollutants: 'Pollutants',
    other: 'Other problems',
    repoertsCard: {
      quantity: 'Quantity:',
      typeofOrganism: 'Type of organism:',
      comments: 'Comments:',
      // meteo
      fogtype: 'Fog type:',
      seaSwellType: 'Sea Swell type',
      windType: 'Wind Type:',
      gigFloatingObjects: 'Big Floating Objects:',
      oilPolutionYes: 'Oil Polution: Yes',
      oilPolutionNo: 'Oil Polution: No',
      biologicalPolutionYes: 'Biological Polution: Yes',
      biologicalPolutionNo: 'Biological Polution: No',
      plasticPolutionYes: 'Plastic Polution: Yes',
      plasticPolutionNo: 'Plastic Polution: No',
      stromType: 'Storm type:',
      otherPoll: 'Other Pollution:',
    },
  },
  // login
  login: {
    signin: 'Sign in',
    loginInSystem: 'Login in system?',
    logInsysDescription:
      'Login is required for being able to report any observed sea environment related activities and conditions!',
    btnLogin: 'Login',
    descriptionSignin: {
      main: "If you don't have an account you can",
      reg: 'Register',
      now: 'now',
    },
    fieldsSignIn: {
      userName: 'Username',
      subtitle: 'Please enter your username.',
      password: 'Password',
      subtitlepass: 'Please enter your password.',
      errorMessage: 'You have entered an invalid username or passwor.',
      requiredFieldError: 'This field is required!',
      validEmailAddressError: 'Please enter a valid email!',
      emailErrorCharacters: 'Email cannot exceed 100 characters!',
      passErrorCh: 'Password cannot exceed 30 characters!',
      forgotPassword: 'Forgot password',
      enterValidEmail: 'Please enter your E-mail Address:',
      forgotPasswordMessage1: 'You forgot your password?',
      forgotPasswordMessage2:
        'Enter the email address you registered with and you will receivean email to change your password.',
      changePassTitle: 'Change your password',
      newPass: 'Please enter your new password:',
      confirmNewPass: 'Confirm your new password:',
      showPass: 'Show Password',
      confirmBtn: 'Confirm',
      passMatch: 'Passwords do not match!',
      successSubitMessage:
        'After 10 seconds, we will transfer you to the login page so you can log in with the new password!',
      successMessageForPass: 'The password has been changed successfully!',
      sendEmail: 'Send email',
      resendEmail: 'Resend email',
      successEmailmsg: 'The email has been successfully sent to:',
    },
  },
  // register
  register: {
    signup: 'Sign up',
    registerInSystem: 'Why you need to create your account?',
    registerInsysDescription:
      'This account can be used for accessing more advanced services.',
    btnSignUp: 'Sign Up',
    descriptionSignup: {
      main: 'If you allreay have an account you can',
      login: 'Login',
      now: 'now!',
    },
    fieldsSignUp: {
      // Full name
      fullName: 'Full Name',
      subtitleFN: 'Please enter your First and Last name.',
      errorMsgName:
        'This field must contain your first and last name, and the first two letters of the names must be capitalized!',
      errorMsgReg: 'The registration was not successful. Please try again.',
      // User name
      username: 'Username',
      subtitleUserName: 'Please enter username (20 characters max).',
      errorMsgUserName: 'The username must be between 3 and 20 characters.',
      // Email
      email: 'Email',
      subtitleEmail: 'Please enter valid email address.',
      errorMsgEmail: 'This is not a valid email.',
      // Pass
      password: 'Password',
      subtitlePass:
        'The password must contain: Min: 8 characters (1 uppercase letter, 1 lowercase letter, 1 special character, 1 number); Max: 30 characters.',
      errorMsgPass: 'Invalid password!',
      // Error message foor all fields for registration
      requiredFieldError: 'This field is required!',
    },
  },
  // profile board
  profile: {
    personalInfo: {
      title: 'Personal info',
      userName: 'User name',
      emailAddress: 'Email address',
      inf: 'Info! ',
      info: 'If you wish to post a report please go to the ',
      home: 'Home',
      page: 'page!',
    },
    numberofRep: {
      title: 'Published reports',
      jelly: 'Jellyfish',
      storm: 'Storm',
      meteorological: 'Meteorological conditions',
      polu: 'Pollutants',
      other: 'Other problems',
    },
  },
  //modals
  modals: {
    fillAll: 'Please fill all fields!',
    sent: 'The report has been sent!',
    dragndrop: 'Drag & Drop or Click to Upload',
    jellyM: {
      titleJ: 'Jellyfish Reporting',
      titleA: 'Strom',
      titleM: 'Meteorological Parameters',
      titleP: 'Pollutants of the sea and the coastline',
      titleO: 'Other problems',
      reportTitle: 'Title:',
      reportersName: 'Reporter`s name:',
      dateAndTime: 'Date and time:',
      info: 'Info!',
      infoTxt: 'This section needs access to your location!',
      infoGeolocation:
        'Click on the map to select the exact location of the report!',
      yourLocation: 'Here is your location:',
      quantity: 'Quantity:',
      des: 'Description:',
      type: 'Type of organism:',
      attachImage: 'Attach photo:',
      stormType: 'Storm type:',
      wind: 'Wind:',
      wave: 'Waves:',
      fog: 'Fog:',
      typeContamination:
        'Select type of contamination (can be more than one) :',
      oilPolution: 'Oil Pollution',
      bioPolution: 'Biological Pollution',
      plasticPolution: 'Plastic Pollution',
      bigfloatingpollution: 'Big floating pollution:',
      commentP: 'Other pollution or add some comments:',
      optionStorm: 'Storm',
      optionThunderstorm: 'Thunderstorm',
      optionRain: 'Rain',
      optionHailstorm: 'Hailstorm',
      optionNostorm: 'No storm',
      pleaseSelect: 'Please select',
      // wind
      optionHurricane: 'Hurricane',
      optionStrong: 'Strong',
      optionModerate: 'Moderate',
      optionWeak: 'Weak',
      optionNowind: 'No wind',
      //waves
      optionStrongW: 'Strong : 6m.',
      optionModerateW: 'Moderate : 5m',
      optionWeakW: 'Weak : 1 - 2m.',
      optionNowaves: 'No waves',
      // fog
      optionVeryT: 'Very thick (visibility under 50 meters)',
      optionT: 'Thick (visibility between 50 and 200 meters)',
      optionModerateF: 'Moderate (visibility between 200 and 500 meters)',
      optionWeakF: 'Light (visibility between 500 and 1000 meters)',
      optionNofog: 'No Fog',
      to: 'to',
      selectFile: 'Please select a file in order to submit!',
      selectStorm: 'Please select storm type in order to submit!',
      selectquantity: 'Please select quantity in order to submit',
      selectValue:
        'Please select values from the dropdowns in order to submit!',
      submitReport: 'Submit Report',
    },
  },
  //history
  history: {
    title: 'History',
    description: 'Check of reports you have published so far!',
    tabJ: 'Jellyfish reports',
    tabA: 'Storm reports',
    tabP: 'Pollutants reports',
    tabM: 'Meteorological reports',
    tabO: 'Other reports',
    titleCard: 'Title:',
    detailsCard: 'Details:',
  },
  // About us
  aboutUs: {
    title: 'ABOUT US',
    mission: 'Our mission',
    welcome:
      'Welcome to our community, dedicated to preserving the sea! We are a large group of passionate individuals who share a common goal of protecting our oceans and marine life.',
    ourCommunity:
      'Our community was founded on the belief that the health of the ocean is crucial to the health of our planet, and we strive to raise awareness and inspire action to safeguard the ocean for future generations. We are committed to promoting sustainable practices, reducing pollution, and protecting endangered species.',
    weBelieve:
      'We believe that it is our responsibility to take actions to protect the ocean and its inhabitants, and we welcome anyone who shares our passion to join us in our mission. By working together, we can make a positive impact on the health of the ocean and create a better future for our planet.',
    ourCoismade:
      'Our community is made up of individuals from all walks of life, including environmentalists, scientists, educators, and concerned citizens. We welcome diversity and believe that by coming together and sharing our knowledge and expertise, we can make a greater impact than we could on our own.',
    thankyou:
      'Thank you for your interest in our community! We invite you to join us in our mission to preserve the sea and protect the health of our planet!',
  },
  // Contacts
  contacts: {
    title: 'Get in touch',
    emergency: 'Emergency contact 112',
    emDes:
      'If you have noticed something unusual or a signal that should be submitted immediately to the control authorities, please contact :',
    iara: 'IARA - Fisheries and Aquaculture Executive Agency',
    iaraContact: 'IARA - Contact Form page',
    iaraContactForm: 'IARA - Contact Form',
  },

  // privacy
  privacy: {
    title: 'ICC - Privacy Policy',
    agreement:
      'At ICC website, accessible at web address, one of our main priorities is the privacy of our visitors. This Privacy Policy document is designed to help you understand how we collect, use and safeguard the information you provide to us.',
    agrTitle: '1. Agreement',
    agrSubtitle:
      'By accessing our website, you accept our Privacy Policy and agree to its Terms of use ',
    subLink: ' (link).',
    //
    informationCollectedT: '2. Information collected',
    informationCollectedAutomatically: 'Information collected automatically',
    infoSubtitle:
      'By visiting our Website, our servers automatically collect information provided by your browser. This data may include information such as IP address, browser type and version, language preferences, referring/exit pages and URLs, the time and date of access, the location and other non-personal information.',
    infoSubtitle1: 'Information you provide us by registering for an account',
    infoSubtitle11:
      'To become a subscriber to the Service you will need to create a personal profile by registering with the Service. To register you will need to enter your email address and create a user name and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.',
    privacyOfChildren: 'Privacy of children',
    subTitleChildren:
      'The Website does not knowingly collect or solicit information from anyone under the age of 14, or allow anyone under the age of 14 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 14 without the consent of a parent or guardian, we will delete that information as soon as possible. If you have reason to believe we have collected such information, please contact us at contact@...........',
    //
    useShareInfo: '3. Use and sharing of information',
    useShareInfoSubtitle:
      'We do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent, except as otherwise stated in this Privacy Policy. We do share Personal Information with vendors who are performing services for the Company.',
    useShareInfoSubtitle1:
      'We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request.',
    useShareInfoSubtitle2:
      'In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyzeanalyse use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.',
    useShareInfoSubtitle3:
      'We recommend that you check the Website periodically to stay updated on how your information is used.',
    //
    protectionTitle: '4. Protection measures',
    protectionSubtitle:
      'We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password. We further protect your information from potential security breaches by implementing certain technological security measures. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.',
    userRigth: 'User rights regarding the use of personal information',
    userRigthSubtitle:
      'You have the right at any time to prevent us from contacting you for marketing purposes.  When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional email.',
    linkToOtherSite: 'Link to other Websites',
    linkToOthersSubtitle:
      'As part of the Service, we may provide links to other websites or applications that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. We encourage you to read the privacy terms of other websites before proceeding to use them.',
    //
    changes: '5. Changes and amendments',
    changesSubtitle:
      'We reserve the right to change this policy and our Terms of Use at any time. We will notify our Privacy Policy changes by sending you an email to the contact information you have provided. Please check periodically the Website for updates.',
    //
    cookies: '6. Cookies',
    cookiesSubtitle:
      'ICC Website and Services may use third-party analytics tools that uses `cookies`.The information is used to improve the users` experience and monitor the Website and Services performance.',
    //
    contactTitle: '7. Contact',
    contactSubtitle:
      'In case of any questions or concerns regarding this Policy or the practices of this Website, we encourage you to contact us at:',
    tuVarna: 'Technical University of Varna',
    tuAddress: '1, Studentska str., 9000 Varna, Bulgaria',
    cnt: 'contact@.................',
  },
};
