export const bg = {
  footer: {
    titleHost: ' се хоства от',
    tuVarna: 'Технически университет Варна',
    iliad: '© 2022 - ILIAD',
    icc: 'ICC',
    aboutUs: 'За нас',
    contacts: 'Контакти',
    termsofuse: 'Условия за използване',
    privacyPolicy: 'Политика за повертелност',
  },
  header: {
    login: 'Вход',
    signout: 'Изход',
    profile: 'Профил',
    history: 'Публикувани доклади',
    adminBoard: 'Администраторски панел',
  },
  //home page
  home: {
    home: 'Здравейте ',
    homeGuest: 'Скъпи посетители,',
    welcomeTextGuest:
      'Добре дошли в уеб страницата на ICC, принадлежаща на нашата общност, посветена на опазване на морето и неговите ресурси! Щастливи сме, че решихте да посетите и вероятно да се присъедините към нас в мисията ни да опазим морската среда и да защитим целия живот, който тя поддържа.',
    wlText0G:
      'Моретата са жизненоважна част от нашата планета и наша отговорност е да гарантираме, че са защитени за бъдещите поколения. Работейки заедно, можем да постигнем реални успехи за подобряване благосъстоянието на моретата, океаните и техните обитатели.',
    wlText1G:
      'Има толкова много начини, по които и вие можете да се включите, и да допринесете в постигането на положително въздействие! Помислете за следното... Можете просто да започнете, като наблюдавате и докладвате за замърсявания и необичайни ситуации в крайбрежната зона около вас. Обещаваме, ние да се погрижим за останалото!!! ',
    wlText2G:
      'И накрая, развълнувани сме да ви предложим членство в нашата общност, и очакваме с нетърпение да ви видим част от нас! ',
    wlText3G:
      'Благодарим ви предварително за вашата отдаденост и личен ангажимент към каузата ни да опазим морето!',
    welcomeTextMember:
      'Щастливи сме, че се присъединихте към нас и нашата кауза за опазване на морето и морската среда!',
    welcomeText1Member: 'Започнете своя принос, като публикувате доклад!',
    gategoriesTitle: 'Категории доклади',
    catDes: 'Публикуването на доклад е възможно само с акаунт!',
  },
  // categories
  reports: {
    countReports: 'Брой репорти за последните 24 часа: ',
    jelly: 'Медузи',
    storm: 'Бури',
    meteorological: 'Метеорологични условия',
    pollutants: 'Замърсители на морето и бреговата ивица',
    other: 'Други проблеми',
    subtitle:
      'Ако забележите нещо необичайно, свързано с други проблеми, моля, уведомете ни.',
    subtitleJ:
      'Тук може да докладвате Вашите наблюдения относно наличие и концентрация на медузи.',
    subtitleA:
      'Тук можете да докладвате Вашите наблюдения, свързани с бури и буреносна активност.',
    subtitleM:
      'Тук можете да докладвате Вашите наблюдения, свързани с посока и скорост на вятъра, наличие на мъгла, вълнение на морето и др.',
    subtitleP:
      'Тук можете да докладвате Вашите наблюдения, свързани с установяване на различни типове замърсявания на морето и крайбрежната зона.',
    subtitleO:
      'Тук можете да докладвате за проблеми свързани с морската среда, които не попадат в останалите категории.',
    createRbtn: 'Създай доклад',
  },
  // filters
  filters: {
    filtersTitle: 'Филтри:',
    jelly: 'Медузи',
    storm: 'Буря',
    meteorological: 'Метеорологични',
    pollutants: 'Замърсявания',
    other: 'Други',
    all: 'Всички',
  },
  // map legend
  map: {
    mapTitle: 'Легенда',
    coordinates: 'Координати:',
    jelly: 'Медузи',
    storm: 'Буря',
    meteorological: 'Метеорологични условия',
    pollutants: 'Замърсявания',
    other: 'Други проблеми',
    repoertsCard: {
      quantity: 'Брой медузи:',
      typeofOrganism: 'Вид медуза:',
      comments: 'Кометари:',
      // meteo
      fogtype: 'Мъгла:',
      seaSwellType: 'Вълнение:',
      windType: 'Вятър:',
      gigFloatingObjects: 'Голямо плаващо замърсяване:',
      oilPolutionYes: 'Замърсяване с нефт: Да',
      oilPolutionNo: 'Замърсяване с нефт: Не',
      biologicalPolutionYes: 'Биологично замърсяване: Да',
      biologicalPolutionNo: 'Биологично замърсяване: Не',
      plasticPolutionYes: 'Замърсяване с пластмаса: Да',
      plasticPolutionNo: 'Замърсяване с пластмаса: Не',
      stromType: 'Буря:',
      otherPoll: 'Друго замърсяване',
    },
  },
  // login
  login: {
    signin: 'Вход в системата',
    loginInSystem: 'Защо да влезем в системата?',
    logInsysDescription:
      'Изисква се вход в системата, за да можете да докладвате наблюдавани от Вас дейности и условия, свързани със състоянието на морската среда!',
    btnLogin: 'Вход',
    descriptionSignin: {
      main: 'Ако все още нямате акаунт',
      reg: 'Регистрирайте се',
      now: 'сега',
    },
    fieldsSignIn: {
      userName: 'Потребителско име',
      subtitle: 'Моля въведете потребителско име',
      password: 'Парола',
      subtitlepass: 'Моля въведете парола',
      errorMessage: 'Въвели сте невалидно потребителско име или парола.',
      requiredFieldError: 'Полето е задължително!',
      validEmailAddressError: 'Моля въведете валиден имейл адрес!',
      emailErrorCharacters: 'Имейлът не може да надвишава 100 символа!',
      passErrorCh: 'Паролата не може да бъде по-дълга от 30 символа!',
      forgotPassword: 'Забравена парола',
      enterValidEmail: 'Моля въведете вашия емейл адрес:',
      forgotPasswordMessage1: 'Забравили сте паролата си?',
      forgotPasswordMessage2:
        'Въведете имейл адреса, с който сте се регистрирали, и ще получите имейл за смяна на паролата си.',
      changePassTitle: 'Променете паролата си',
      newPass: 'Моля, въведете новата си парола:',
      confirmNewPass: 'Потвърдете новата си парола:',
      showPass: 'Покажи паролата',
      confirmBtn: 'Потвърди',
      passMatch: 'Паролите не съвпадат!',
      successSubitMessage:
        'След 10 секунди ще ви прехвърлим към страницата за вход, така че да можете да влезете с новата си парола!',
      successMessageForPass: 'Паролата беше променена успешно!',
      sendEmail: 'Изпрати имейл за смяна на парола',
      resendEmail: 'Изпрати отново',
      successEmailmsg: 'Имейлът беше успешно изпратен на:',
    },
  },
  // register
  register: {
    signup: 'Регистрация',
    registerInSystem: 'Защо Ви е необходим акаунт?',
    registerInsysDescription:
      'Този акаунт може да се използва за достъп до по-разширени услуги.',
    btnSignUp: 'Регистрирай се',
    descriptionSignup: {
      main: 'Ако вече имате акаунт, можете да',
      login: 'влезете',
      now: 'сега!',
    },
    fieldsSignUp: {
      // Full name
      fullName: 'Име и Фамилия',
      subtitleFN: 'Моля, въведете вашето име и фамилия на латиница.',
      errorMsgName:
        'Това поле трябва да съдържа Вашето име и фамилия, като първите две букви от имената трябва да са главни!',
      errorMsgReg: 'Регистрацията не беше успешна. Моля, опитайте отново.',
      // User name
      username: 'Потребителско име',
      subtitleUserName:
        'Моля, въведете потребителско име до 20 знака максимум без празно място.',
      errorMsgUserName: 'Потребителското име трябва да е между 4 и 20 знака.',
      // Email
      email: 'Имейл',
      subtitleEmail: 'Моля въведете валиден имейл адрес.',
      errorMsgEmail: 'Опитайте отново с валиден имейл адрес.',
      // Pass
      password: 'Парола',
      subtitlePass:
        'Паролата трябва да съдържа: Мин.: 8 знака (1 главна буква, 1 малка буква, 1 специален знак, 1 цифра); Макс.: 30 знака.',
      errorMsgPass: 'Невалидна парола!',
      // Error message foor all fields for registration
      requiredFieldError: 'Полето е задължително!',
    },
  },
  // profile board
  profile: {
    personalInfo: {
      title: 'Лична информация',
      userName: 'Потребителско име:',
      emailAddress: 'Имейл адрес:',
      inf: 'Иформация! ',
      info: 'Ако желаете да публикувате доклад моля отидете на',
      home: 'Началната',
      page: 'страница!',
    },
    numberofRep: {
      title: 'Публикувани доклади',
      jelly: 'Медузи',
      storm: 'Буря',
      meteorological: 'Метеорологични условия',
      polu: 'Замърсявания',
      other: 'Други проблеми',
    },
  },
  //modals
  modals: {
    fillAll: 'Моля попълнете всички полета!',
    sent: 'Доклада е изпратен',
    dragndrop: 'Прикачете снимка, като я пуснете тук или като натиснете',
    jellyM: {
      titleJ: 'Докладване на медузи',
      titleA: 'Буря',
      titleM: 'Метеорологични условия',
      titleP: 'Замърсители на морето и бреговата ивица',
      titleO: 'Други проблеми',
      reportTitle: 'Име на доклад:',
      reportersName: 'Име на потребител:',
      dateAndTime: 'Час и дата:',
      info: 'Информация!',
      infoTxt: 'Този раздел се нуждае от достъп до вашето местоположение!',
      infoGeolocation:
        'Натиснете на картата за да изберете точното местоположението на доклада!',
      yourLocation: 'Вашето местоположение:',
      quantity: 'Брой медузи:',
      des: 'Описание и коментари:',
      type: 'Тип организъм:',
      attachImage: 'Прикачи снимка:',
      stormType: 'Вид буря:',
      wind: 'Вятър:',
      wave: 'Вълнение:',
      fog: 'Мъгла:',
      typeContamination:
        'Изберете тип замърсяване (може да бъде повече от един тип) :',
      oilPolution: 'Замърсяване с нефт',
      bioPolution: 'Биологично замърсяване',
      plasticPolution: 'Замърсяване с пластмаса',
      bigfloatingpollution: 'Голямо плаващо замърсяване:',
      commentP: 'Друго замърсяване или добавете коментар:',
      optionStorm: 'Буря',
      optionThunderstorm: 'Гръмотевична буря',
      optionRain: 'Дъжд',
      optionHailstorm: 'Градушка',
      optionNostorm: 'Без буря',
      pleaseSelect: 'Моля изберете',
      // wind
      optionHurricane: 'Ураганен вятър',
      optionStrong: 'Силен вятър',
      optionModerate: 'Умерен вятър',
      optionWeak: 'Слаб вятър',
      optionNowind: 'Без вятър',
      //waves
      optionStrongW: 'Силно вълнение : 6m.',
      optionModerateW: 'Умерено вълнение : 5m',
      optionWeakW: 'Слабо вълнение : 1 - 2m.',
      optionNowaves: 'Без вълнение',
      // fog
      optionVeryT: 'Без видимост (видимост под 50 метра).',
      optionT: 'Слаба видимост (видимост между 50 и 200 метра).',
      optionModerateF: 'Умерена видимост (видимост между 200 и 500 метра).',
      optionWeakF: 'Видимост между 500 и 1000 метра.',
      optionNofog: 'Без мъгла',
      to: ' до ',
      selectFile: 'Изберете снимка, за да изпратите доклада!',
      selectStorm: 'Изберете вид буря, за да изпратите доклада!',
      selectquantity: 'Изберете брой медузи, за да изпратите доклада!',
      selectValue:
        'Изберете опция от падащите менюта, за да изпратите доклада!',
      submitReport: 'Изпращане на доклад',
    },
  },
  //history
  history: {
    title: 'История',
    description: 'Проверка на отчетите, които сте публикували до момента!',
    tabJ: 'Доклади за медузи',
    tabA: 'Доклади за буря',
    tabP: 'Доклади за замърсители',
    tabM: 'Метеорологични доклади',
    tabO: 'Други доклади',
    titleCard: 'Име на доклад:',
    detailsCard: 'Детайли:',
  },
  // About us
  aboutUs: {
    title: 'ЗА НАС',
    mission: 'Нашата Мисия',
    welcome:
      'Добре дошли в нашата общност, посветена на опазването на морето! Ние сме голяма група от отдадени хора, споделящи обща цел за защита на океаните и моретата, както и техните обитатели.',
    ourCommunity:
      'Нашата общност е основана на убеждението, че здравето на океана е от решаващо значение за здравето на нашата планета. Ние се стремим да повишим осведомеността и да вдъхновим обществото за осъществяване на действия за опазване на океани, морета и морската среда за бъдещите поколения. Ангажирани сме с  активности за насърчаване на устойчиви практики, намаляване на замърсяването и защита на застрашените видове.',
    weBelieve:
      'Вярваме, че е наша отговорност да предприемем действия за защита на световния океан и неговите обитатели, и приветстваме всеки, който споделя нашата страст, да се присъедини към тази благородна кауза. Работейки заедно, можем да окажем положително въздействие върху здравето на океана и да създадем по-добро бъдеще за нашата планета. ',
    ourCoismade:
      'Нашата общност се състои от хора от всички сфери на живота, включително еколози, учени, преподаватели и загрижени граждани. Ние приветстваме разнообразието и вярваме, че като се обединим и споделим нашите знания и опит, можем да постигнем по-голямо въздействие, отколкото бихме могли сами.',
    thankyou:
      'Благодарим ви за интереса към нашата общност и най-учтиво ви каним ви да се присъедините към мисията ни за опазване на морската среда, а от там и здравето на нашата планета!',
  },
  // Contacts
  contacts: {
    title: 'Свържете се с:',
    emergency: 'Спешен телефон 112',
    emDes:
      'Ако забележите нещо необичайно или сигнал, който трябва незабавно да подадете на контролните органи, моля свържете се с:',
    iara: 'ИАРА - Изпълнителна агенция по рибарство и аквакултури',
    iaraContact: 'ИАРА - страница с формуляр за контакт',
    iaraContactForm: 'ИАРА - контактна форма',
  },
  // privacy
  privacy: {
    title: 'ICC - Политика за поверителност',
    agreement:
      'Eдин от основните ни приоритети при достъпа на уебсайта на ICC е поверителността на нашите посетители. Този документ „Политика за поверителност“ е предназначен да Ви помогне да разберете как събираме, използваме и защитаваме информацията, която ни предоставяте.',
    agrTitle: '1. Споразумение',
    agrSubtitle:
      'С достъпа до нашия уебсайт Вие приемате нашата „Политика за поверителност“ и се съгласявате с нейните Условия за ползване',
    subLink: ' (линк).',
    //
    informationCollectedT: '2. Събрана информация',
    informationCollectedAutomatically: 'Автоматично събиране на информация',
    infoSubtitle:
      'Посещавайки нашия уебсайт, нашите сървъри автоматично събират информация, предоставена от вашия браузър. Тези данни може да включват информация като IP адрес, тип и версия на браузъра, езикови предпочитания, препращащи/изходни страници и URL адреси, час и дата на достъп, местоположение и друга не лична информация.',
    infoSubtitle1:
      'Информация, която ни предоставяте при регистрация на акаунт',
    infoSubtitle11:
      'За да станете абонат на Услугата, трябва да създадете личен профил, като се регистрирате в уебсайта. За да се регистрирате, трябва да въведете своя имейл адрес и да създадете потребителско име и парола. Чрез регистрацията Вие ни разрешавате да събираме, съхраняваме и използваме вашия имейл адрес в съответствие с тази „Политика за поверителност“.',
    privacyOfChildren: 'Поверителност на децата',
    subTitleChildren:
      'Уебсайтът не събира и/или не изисква информация от лица под 14-годишна възраст и не позволява на лица под 14-годишна възраст да се регистрират за Услугата. В случай, че научим, че сме събрали лична информация от лице под 14-годишна възраст без съгласието на родител или настойник, ние ще изтрием тази информация възможно най-скоро. Ако имате причина да смятате, че сме събрали такава информация, моля, свържете се с нас на contact@...........',
    //
    useShareInfo: '3. Използване и споделяне на информация',
    useShareInfoSubtitle:
      'Ние не продаваме, търгуваме, отдаваме под наем или по друг начин споделяме за маркетингови цели Вашата лична информация с трети страни без вашето съгласие, освен ако не е посочено друго в тази „Политика за поверителност“. Ние споделяме лична информация с доставчици, които ни предоставят услуги.',
    useShareInfoSubtitle1:
      'Възможно е да споделим лична информация с външни страни, ако имаме добросъвестно убеждение, че достъпът, използването, запазването или разкриването на информацията е изискан във връзка със съдебен процес или от оправомощен държавен орган.',
    useShareInfoSubtitle2:
      'Използваме не лична информация, за да ни помогне да подобрим Услугата и да персонализираме потребителското изживяване. Освен това събираме не лична информация, и за да проследяваме тенденциите и да анализираме моделите на използване на сайта. Тази „Политика за поверителност“ не ограничава по никакъв начин използването от наша страна или разкриване на не лична информация и ние си запазваме правото да използваме и разкриваме такава не лична информация на нашите партньори, рекламодатели и други трети страни по наше усмотрение.',
    useShareInfoSubtitle3:
      'Препоръчваме Ви периодично да проверявате Уебсайта, за да сте информирани как се използва Вашата информация.',
    //
    protectionTitle: '4. Мерки за защита',
    protectionSubtitle:
      'Прилагаме мерки за сигурност, предназначени да предпазят Вашата информация от неоторизиран достъп. Вашият акаунт е защитен от паролата за акаунта Ви. Ние допълнително защитаваме Вашата информация от потенциални пробиви в сигурността, като прилагаме определени технологични мерки за сигурност. Въпреки това, тези мерки не гарантират, че Вашата информация няма да бъде достъпна, разкрита, променена или унищожена чрез нарушаване на такива защитни стени и защитен сървърен софтуер. Като използвате нашата услуга, вие потвърждавате, че разбирате и се съгласявате да поемете тези рискове.',
    userRigth:
      'Права на потребителите относно използването на лична информация',
    userRigthSubtitle:
      'Имате право по всяко време да прекратите контакта ни с Вас за маркетингови цели. Когато изпратим промоционално съобщение до потребител, потребителят може да се откаже от по-нататъшни промоционални съобщения, като следва инструкциите за отписване, предоставени във всеки промоционален имейл.',
    linkToOtherSite: 'Връзка към други уебсайтове',
    linkToOthersSubtitle:
      'Като част от Услугата, ние може да предоставим връзки към други уебсайтове или приложения, които не са собственост или контролирани от нас. Моля, имайте предвид, че ние не носим отговорност за практиките за поверителност, използвани от тези уебсайтове, или информацията или съдържанието, което те съдържат. Тази „Политика за поверителност“ се прилага единствено за информация, събрана от нас чрез Сайта и Услугата. Съветваме ви да прочетете условията за поверителност на други уебсайтове, преди да продължите да ги използвате.',
    //
    changes: '5. Промени и допълнения',
    changesSubtitle:
      'Ние си запазваме правото да променяме тази политика и нашите Условия за ползване по всяко време. Ще уведомим за промените в нашата Политика за поверителност, като Ви изпратим имейл на предоставената от вас информация за контакт. Моля, проверявайте периодично уебсайта за актуализации.',
    //
    cookies: '6. Бисквитки',
    cookiesSubtitle:
      'Уебсайтът и услугите на ICC може да използват инструменти за анализ на трети страни, които използват „бисквитки“. Информацията се използва за подобряване на опита на потребителите и за наблюдение на ефективността на уебсайта и услугите.',
    //
    contactTitle: '7. Контакт',
    contactSubtitle:
      'В случай на въпроси или притеснения относно тази Политика или практиките на този уебсайт, препоръчваме ви да се свържете с нас на:',
    tuVarna: 'Технически университет Варна',
    tuAddress: 'ул. Студентска 1, 9000 Варна, България',
    cnt: 'контакт@.................',
  },
};
