import "bootstrap/dist/js/bootstrap.js";

import React, { useEffect, useState } from "react";

import Moment from "react-moment";
import axios from "axios";
import configUrl from "../../../configuration-data.json";
import { useTranslation } from "react-i18next";

function GetMyReporstOther() {
  const test = JSON.parse(localStorage.getItem("user"));

  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();

  //   const jsonDate = JSON.stringify({
  //     from: '2023-01-30T07:00:10.22',
  //     to: new Date(),
  //   });

  useEffect(() => {
    // console.log(jsonDate);
    axios
      .get(configUrl.SERVER_URL + "BaseReports/GetMyReports", {
        // jsonDate,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json: charset=utf-8",
          Authorization: `Bearer ${test.token}`,
        },
      })
      .then((response) => {
        setPosts(response.data);
        console.log(response.data);
      });
  }, [test.token]);
  return (
    <div className="admin__section-body">
      <div className="row">
        {posts.map((repostO) => (
          <div key={repostO.id} className="col-12 col-sm-6 col-md-4">
            <div className="card card-different--color">
              <img
                className="card-img-top card-image--user"
                src={repostO?.reportPhoto?.photo}
                alt="Card cap"
              />
              {/* <div className="card-body">
                <h5 className="card-title">{repostO.reportName}</h5>
              </div> */}
              <div className="card-body">
                <span className="sub-title">{t("history.titleCard")}</span>
                <h5 className="card-title"> {repostO.reportName}</h5>
                <hr />
                <span className="sub-title">{t("history.detailsCard")}</span>

                <h5 className="cart-title-history">
                  <span className="icon icon-comments"></span>
                  {repostO.comment}
                </h5>
              </div>
              <Moment
                className="txt-align-r pr-3 py-3 background-color--light-green"
                format="DD-MM-YYYY HH:mm"
                date={repostO.reportDate}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GetMyReporstOther;
