import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import configUrl from '../configuration-data.json';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ChangePassword = (props) => {
  const [count, setCount] = useState(1);
  const [formData, setFormData] = useState({});
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get('email');
  const t = searchParams.get('t');

  const { t: translate } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 10) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    if (isEmailSent) {
      const timeout = setTimeout(() => {
        navigate('/login');
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [isEmailSent, navigate]);

  const sendPostRequest = async (email, t, password, confirmPassword) => {
    try {
      const response = await axios.post(
        configUrl.SERVER_URL + 'PasswordReset/ResetPassword',
        {
          email: email,
          token: t.replace(/\s+/g, '+'),
          password: password,
          confirmPassword: confirmPassword,
        }
      );
      // console.log('success post request:', response.data);
    } catch (error) {
      console.error('Error post request', error);
    }
  };

  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      setIsEmailSent(true);
      setFormData(data);
      setCount(1);
      sendPostRequest(email, t, data.password, data.confirmPassword);
    } else {
      setIsEmailSent(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-wrapper forgot-password-wrapper">
        {isEmailSent && (
          <p className="info-txt-pass">
            {translate('login.fieldsSignIn.successSubitMessage')}
          </p>
        )}
        <h1 className="title-forgot-pass">
          {translate('login.fieldsSignIn.changePassTitle')}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="ds-block email-field">
            {translate('login.fieldsSignIn.newPass')}
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            {...register('password', {
              required: true,
              maxLength: 30,
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,30}$/,
            })}
          />
          {errors?.password?.type === 'required' && (
            <p className="error-messages">
              {translate('login.fieldsSignIn.requiredFieldError')}*
            </p>
          )}
          {errors?.password?.type === 'maxLength' && (
            <p className="error-messages">
              {translate('login.fieldsSignIn.passErrorCh')}
            </p>
          )}
          {errors?.password?.type === 'pattern' && (
            <p className="error-messages">
              {translate('register.fieldsSignUp.subtitlePass')}
            </p>
          )}

          <label className="ds-block email-field mt-4">
            {translate('login.fieldsSignIn.confirmNewPass')}
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            {...register('confirmPassword', {
              required: true,
              validate: (value) =>
                value === password || 'Passwords do not match',
            })}
          />
          {errors?.confirmPassword?.type === 'required' && (
            <p className="error-messages">
              {' '}
              {translate('login.fieldsSignIn.requiredFieldError')}*
            </p>
          )}
          {errors?.confirmPassword?.type === 'validate' && (
            <p className="error-messages">
              {translate('login.fieldsSignIn.passMatch')}
            </p>
          )}

          <div className="show-password-toggle float-right d-flex mt-1">
            <input
              type="checkbox"
              id="showPassword"
              onChange={togglePasswordVisibility}
              checked={showPassword}
            />
            <p
              className="sub-title font-weight-bold ml-1"
              htmlFor="showPassword"
            >
              {translate('login.fieldsSignIn.showPass')}
            </p>
          </div>
          {isEmailSent && (
            <div>
              <div className="alert alert-success mt-5" role="alert">
                {translate('login.fieldsSignIn.successMessageForPass')}
              </div>
              <div className="count-wrapper">
                <h1>{count}</h1>
              </div>
            </div>
          )}
          <div className="form-group">
            <button className="submit-email-btn">
              {translate('login.fieldsSignIn.confirmNewPass')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
