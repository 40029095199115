import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import GetLocation from "./GetLocation";
import Input from "react-validation/build/input";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { jellyfish } from "../../../actions/auth";
import { setShowJ } from "./../../../actions/modalActions";
import { useTranslation } from "react-i18next";

const JellyfishModal = ({ handleReportsUpdated, title, show, handleClose }) => {
  const [startDate, setStartDate] = useState("");

  const form = useRef();
  const checkBtn = useRef();

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [reportName, setreportName] = useState("");
  const [userId, setuserId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [comment, setComment] = useState("");
  const [species, setSpecies] = useState("");
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [reportPhotoString, setBaseImage] = useState([]);

  const { t } = useTranslation();

  const [fileInput, setFileInput] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const [successMessage, setsuccessMessage] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const containerStyle = {
    width: "100%",
    height: "150px",
    border: "2px dashed #007BFF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  };

  const labelStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#007BFF",
  };

  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function: This will ensure that the overflow is reset even if the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  useEffect(() => {
    setuserId(currentUser.userName);

    setStartDate(
      new Date(
        new Date().setHours(new Date().getHours(), new Date().getMinutes())
      )
    );
  }, [currentUser.userName]);

  //add
  useEffect(() => {
    if (message === "success") {
      // clearForm();
      setValidateForm(false);
    }
  }, [message]);

  if (!show) {
    return null;
  }

  //add
  const required = (value) => {
    if (!value && validateForm) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const onChangeReportName = (e) => {
    const reportName = e.target.value;
    setreportName(reportName);
  };

  // add this for select

  const onChangeQuantity = (quantity) => {
    setQuantity(quantity.value);
  };

  const onChangeComment = (e) => {
    const comment = e.target.value;
    setComment(comment);
  };

  const onChangeSpecies = (e) => {
    const species = e.target.value;
    setSpecies(species);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      if (e.dataTransfer.items[0].kind === "file") {
        const file = e.dataTransfer.items[0].getAsFile();
        handleFile(file);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      const file = e.dataTransfer.files[0];
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    // Essentially the same logic as onFileChange but for the dropped file
    setUploading(true);

    setFileInput(true);
    setUploadedFileName(file.name);

    try {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const onFileChange = (e) => {
    setUploading(true);

    // add thus for select and btn
    if (e.target.files[0]) {
      setFileInput(true);
      setUploadedFileName(e.target.files[0].name);
    }
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          setBaseImage(uri);
          setUploading(false);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const handleReportJellyfish = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (
      checkBtn.current.context._errors.length === 0 &&
      reportName &&
      userId &&
      quantity &&
      comment &&
      species &&
      fileInput &&
      latitude &&
      longitude
    ) {
      setStartDate(
        new Date(
          new Date().setHours(new Date().getHours(), new Date().getMinutes())
        )
      );
      setsuccessMessage(true);

      dispatch(setShowJ(false));

      dispatch(
        jellyfish(
          quantity,
          species,
          userId,
          reportName,
          startDate,
          latitude,
          longitude,
          comment,
          reportPhotoString || ""
        )
      )
        .then(() => {
          // add this for select and btn
          handleReportsUpdated(true);
          setFileInput(false);
        })
        .catch(() => {
          handleReportsUpdated(true);
        });
    } else {
      setDisplayError(true);
      setValidateForm(true); // This will trigger the 'required' validations to show their error messages
    }
  };

  return (
    <div
      className="modal-container"
      onClick={() =>
        dispatch(
          setShowJ(false),
          setShowJ(false)
            ? (document.body.style.overflow = "auto")
            : (document.body.style.overflow = "hidden")
        )
      }
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleReportJellyfish} ref={form}>
          <div className="modal-header">
            <div className="modal-title">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="ds-block username-field mb-3">
                {t("modals.jellyM.reportTitle")}
                <Input
                  type="text"
                  value={reportName}
                  name="reportName"
                  onChange={onChangeReportName}
                  validations={[required]}
                ></Input>
              </label>
              <label className="mb-3 w-100">
                {" "}
                {t("modals.jellyM.dateAndTime")}
                <DatePicker
                  selected={startDate}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  className="form-control"
                  readOnly
                />
              </label>
              <GetLocation
                latitude={latitude}
                longitude={longitude}
                setLat={setLat}
                setLng={setLng}
              />
              <div className="alert alert-warning" role="alert">
                {t("modals.jellyM.infoGeolocation")}
              </div>
              <label className="ds-block ds-coast-field mb-3">
                {t("modals.jellyM.quantity")}
                {/* add for select */}
                <Select
                  isSearchable={false}
                  id="jellyselect"
                  defaultValue={{ label: t("modals.jellyM.pleaseSelect") }}
                  name="quantity"
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" + t("modals.jellyM.to") + "5" },
                    { value: "3", label: "6" + t("modals.jellyM.to") + " 10" },
                    { value: "4", label: "11" + t("modals.jellyM.to") + " 99" },
                    { value: "5", label: "100+" },
                  ]}
                  onChange={onChangeQuantity}
                  validations={[required]}
                />
              </label>
              <label className="ds-block comments-field mb-3">
                {t("modals.jellyM.des")}
                <Input
                  type="text"
                  name="comment"
                  value={comment}
                  onChange={onChangeComment}
                  validations={[required]}
                ></Input>
              </label>
              <label className="ds-block species-field mb-3">
                {t("modals.jellyM.type")}
                <Input
                  type="text"
                  name="species"
                  value={species}
                  onChange={onChangeSpecies}
                  validations={[required]}
                ></Input>
              </label>
              <p>{t("modals.jellyM.attachImage")}</p>
              <div
                className="file-upload-container"
                onClick={handleClick}
                style={containerStyle}
                onDragOver={onDragOver}
                onDrop={onDrop}
              >
                <input
                  type="file"
                  id="fileInput"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <label
                  htmlFor="fileInput"
                  className="file-upload-label"
                  style={labelStyle}
                >
                  {uploading
                    ? "Loading..."
                    : uploadedFileName
                    ? uploadedFileName
                    : t("modals.dragndrop")}
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {t("modals.sent")}
              </div>
            )}
            {displayError && (
              <div className="alert alert-danger" role="alert">
                {t("modals.fillAll")}
              </div>
            )}
            <button className="sub-report">
              {t("modals.jellyM.submitReport")}
            </button>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </div>
        </Form>
        <button
          className="btn-colse-modal"
          onClick={() => dispatch(setShowJ(false))}
        ></button>
      </div>
    </div>
  );
};

export default JellyfishModal;
