import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { bg } from "./i18n/bg";
import { en } from "./i18n/en";
import { ru } from "./i18n/ru";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("language") || "bg",
  lowerCaseLng: true,
  fallbackLng: "bg",
  interpolation: {
    escapeValue: false,
  },
  react: { wait: true },
  resources: {
    bg: { translation: bg },
    en: { translation: en },
    ru: { translation: ru },
  },
});

export default i18n;
