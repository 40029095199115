import "../Styles/Footer.css";

import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-row ds-flex">
          <div className="footer-first-column">
            <div className="title-column">
              <p>
                <span className="headline-green">ICC</span>
                {t("footer.titleHost")}
              </p>
              <p>{t("footer.tuVarna")}</p>
            </div>
            <div className="iliad-text">
              <p>© 2022 - ILIAD</p>
            </div>
          </div>
          <div className="footer-second-column">
            <div className="title-column">
              <p>
                <span className="headline-green">ICC</span>
              </p>
              <div className="links-footer">
                <a href="/about-us">{t("footer.aboutUs")}</a>
                <a href="/contact">{t("footer.contacts")}</a>
                <a href="/terms-of-use">{t("footer.termsofuse")}</a>
                <a href="/privacy-policy">{t("footer.privacyPolicy")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
