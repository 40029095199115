import React, { useState, useEffect } from 'react';
import {
  setShowA,
  setShowJ,
  setShowM,
  setShowO,
  setShowP,
} from './../../actions/modalActions';
import { useDispatch, useSelector } from 'react-redux';

import AtypicalActModal from '../map-page/reporting-modals/AtypicalAct-modal';
import JellyfishModal from '../map-page/reporting-modals/Jellyfish-modal';
import MeteorologicalParamModal from '../map-page/reporting-modals/MeteorologicalParam-modal';
import Modal from './../modals/modal-info-reports';
import OtherModal from '../map-page/reporting-modals/Other-modal';
import PollutantstModal from '../map-page/reporting-modals/Pollutants-modal';
import atypicalIcon from '../../Assetss/atypical.png';
import axios from 'axios';
import configUrl from '../../configuration-data.json';
import jellyfish from '../../Assetss/jellyfish.jpg';
import jellyfishIcon from '../../Assetss/jellyfish-icon.png';
import meteoIcon from '../../Assetss/meteo-icon.png';
import oceanPollution from '../../Assetss/ocean-pollution.jpg';
import otherIcon from '../../Assetss/other-icon.png';
import otherProblems from '../../Assetss/weather-frame.jpg';
import pollutantsIcon from '../../Assetss/pollutants.png';
import storm from '../../Assetss/storm1.jpg';
import { useTranslation } from 'react-i18next';
import wave from '../../Assetss/wave.jpeg';

const MapForm = ({ handleReportsUpdated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportIcon, setReportIcon] = useState(null);
  const modalReducer = useSelector((state) => state.modalReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reportDataJelly, setReportDataJelly] = useState([]);
  const [reportDataStorm, setReportDataStorm] = useState([]);
  const [reportDataPoll, setReportDataPoll] = useState([]);
  const [reportDataMeteo, setReportDataMeteo] = useState([]);
  const [reportDataOther, setReportDataOther] = useState([]);

  useEffect(() => {
    setReportDataJelly(fetchReports('jelly'));
    setReportDataStorm(fetchReports('storm'));
    setReportDataPoll(fetchReports('poll'));
    setReportDataMeteo(fetchReports('meteo'));
    setReportDataOther(fetchReports('other'));
  }, []);

  const setReportData = (response, type) => {
    console.log(response, type);
    switch (type) {
      case 'jelly':
        setReportDataJelly(response);
        break;
      case 'storm':
        setReportDataStorm(response);
        break;
      case 'poll':
        setReportDataPoll(response);
        break;
      case 'meteo':
        setReportDataMeteo(response);
        break;
      case 'other':
        setReportDataOther(response);
        break;
    }
  };

  const showModalJ = (state) => {
    setShowJ(state);
  };

  const showModalA = (state) => {
    setShowA(state);
  };

  const showModalM = (state) => {
    setShowM(state);
  };

  const showModalP = (state) => {
    setShowP(state);
  };

  const showModalO = (state) => {
    setShowO(state);
  };

  const fetchReports = (type) => {
    let url = '';
    switch (type) {
      case 'jelly':
        url = 'JellyfishReports/GetReportsRange';
        setReportIcon(jellyfishIcon);
        break;
      case 'storm':
        url = 'AtypicalActivities/GetReportsRange';
        setReportIcon(atypicalIcon);
        break;
      case 'poll':
        url = 'PollutantsOfTheSeaAndCoastline/GetReportsRange';
        setReportIcon(pollutantsIcon);
        break;
      case 'meteo':
        url = 'MeteorologicalParameters/GetReportsRange';
        setReportIcon(meteoIcon);
        break;
      case 'other':
        url = 'BaseReports/GetReportsRange';
        setReportIcon(otherIcon);
        break;
      default:
        url = 'JellyfishReports/GetReportsRange';
        setReportIcon(jellyfishIcon);
        break;
    }

    axios
      .get(configUrl.SERVER_URL + `${url}`, {
        params: {
          from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
          to: new Date(),
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json: charset=utf-8',
        },
      })
      .then((response) => {
        setReportData(response.data, type);
      });
  };

  const handleOpenModal = (type) => {
    fetchReports(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="map-form">
      <div className="container-categories">
        <div className="categories-wrapper">
          <div className="card category-card">
            <span className="report-count">
              {t('reports.countReports')}
              {reportDataJelly !== undefined ? reportDataJelly.length : 0}
            </span>
            <img
              src={jellyfish}
              alt="category"
              onClick={() => handleOpenModal('jelly')}
            />
            <div className="card-body card-body--white">
              {/* here J */}
              <h5 className="category-title">{t('reports.jelly')}</h5>
              <p className="card-text">{t('reports.subtitleJ')}</p>
              <button
                className="create-report-button"
                onClick={() =>
                  dispatch(
                    setShowJ(true)
                    // setShowJ(true)
                    //   ? (document.body.style.overflow = 'hidden')
                    //   : (document.body.style.overflow = 'unset')
                  )
                }
              >
                {t('reports.createRbtn')}
              </button>
            </div>
          </div>
          <div className="card category-card">
            <span className="report-count">
              {t('reports.countReports')}{' '}
              {reportDataStorm !== undefined ? reportDataStorm.length : 0}
            </span>
            <img src={storm} alt="storm" />
            <div className="card-body card-body--white">
              {/* here S*/}
              <h5 className="category-title">{t('reports.storm')}</h5>
              <p className="card-text">{t('reports.subtitleA')}</p>
              <button
                className="create-report-button"
                onClick={() =>
                  dispatch(
                    setShowA(true)
                    // setShowA(true)
                    //   ? (document.body.style.overflow = 'hidden')
                    //   : (document.body.style.overflow = 'unset')
                  )
                }
              >
                {t('reports.createRbtn')}
              </button>
            </div>
          </div>
          <div className="card category-card">
            <span className="report-count">
              {t('reports.countReports')}
              {reportDataMeteo !== undefined ? reportDataMeteo.length : 0}
            </span>
            <img src={wave} alt="wave" />
            <div className="card-body card-body--white">
              {/* here M*/}
              <h5 className="category-title">{t('reports.meteorological')}</h5>
              <p className="card-text">{t('reports.subtitleM')}</p>
              <button
                className="create-report-button"
                onClick={() =>
                  dispatch(
                    setShowM(true)
                    // setShowM(true)
                    //   ? (document.body.style.overflow = 'hidden')
                    //   : (document.body.style.overflow = 'unset')
                  )
                }
              >
                {t('reports.createRbtn')}
              </button>
            </div>
          </div>
          <div className="card category-card">
            <span className="report-count">
              {t('reports.countReports')}
              {reportDataPoll !== undefined ? reportDataPoll.length : 0}
            </span>
            <img src={oceanPollution} alt="ocean" />
            <div className="card-body card-body--white">
              {/* here P*/}
              <h5 className="category-title">{t('reports.pollutants')}</h5>
              <p className="card-text">{t('reports.subtitleP')}</p>
              <button
                className="create-report-button"
                onClick={() =>
                  dispatch(
                    setShowP(true)
                    // setShowP(true)
                    // ? (document.body.style.overflow = 'hidden')
                    // : (document.body.style.overflow = 'unset')
                  )
                }
              >
                {t('reports.createRbtn')}
              </button>
            </div>
          </div>
          <div className="card category-card">
            <span className="report-count">
              {t('reports.countReports')}
              {reportDataOther !== undefined ? reportDataOther.length : 0}
            </span>
            <img src={otherProblems} alt="other problems" />
            <div className="card-body card-body--white">
              {/* here O*/}
              <h5 className="category-title">{t('reports.other')}</h5>
              <p className="card-text">{t('reports.subtitleO')}</p>
              <button
                className="create-report-button"
                onClick={() =>
                  dispatch(
                    setShowO(true)
                    // setShowO(true)
                    //   ? (document.body.style.overflow = 'hidden')
                    //   : (document.body.style.overflow = 'unset')
                  )
                }
              >
                {t('reports.createRbtn')}
              </button>
            </div>
          </div>
        </div>
        {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal}> */}
        {/* <h2>Заглавие на категория</h2> */}
        {/* <div className="modal-info">
            <h5>През последните 24 часа са регистрирани</h5>
            <img className="reports-icons" alt="reports" src={reportIcon} />
            <h2>{reportData !== null ? reportData.length : 0}</h2>
          </div>
        </Modal> */}
      </div>
      <JellyfishModal
        handleReportsUpdated={handleReportsUpdated}
        title={t('modals.jellyM.titleJ')}
        show={modalReducer.showJ}
        handleClose={() => showModalJ(false)}
      />
      <AtypicalActModal
        handleReportsUpdated={handleReportsUpdated}
        title={t('modals.jellyM.titleA')}
        show={modalReducer.showA}
        handleClose={() => showModalA(false)}
      />
      <MeteorologicalParamModal
        handleReportsUpdated={handleReportsUpdated}
        title={t('modals.jellyM.titleM')}
        show={modalReducer.showM}
        handleClose={() => showModalM(false)}
      />
      <PollutantstModal
        handleReportsUpdated={handleReportsUpdated}
        title={t('modals.jellyM.titleP')}
        show={modalReducer.showP}
        handleClose={() => showModalP(false)}
      />
      <OtherModal
        handleReportsUpdated={handleReportsUpdated}
        title={t('modals.jellyM.titleO')}
        show={modalReducer.showO}
        handleClose={() => showModalO(false)}
      />
    </div>
  );
  // }
};

export default MapForm;
