export const ru = {
  footer: {
    titleHost: ' ПРИНАДЛЕЖИТ',
    tuVarna: 'Технический университет Варны TUV',
    iliad: '© 2022 - ILIAD',
    icc: 'ICC',
    aboutUs: 'О НАС',
    contacts: 'КОНТАКТЫ',
    termsofuse: 'УСЛОВИЯ ИСПОЛЬЗОВАНИЯ',
    privacyPolicy: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
  },
  header: {
    login: 'Войти',
    signout: 'Выйти',
    profile: 'Профиль',
    history: 'История отчетов',
    adminBoard: 'Панель администратора',
  },
  home: {
    home: 'Добро пожаловать',
    homeGuest: 'Уважаемый посетитель,',
    welcomeTextGuest:
      'Добро пожаловать на веб-страницу ICC - принадлежащую нашему сообществу, посвященному сохранению моря! Мы так рады, что вы решили посетить нас и, возможно, присоединиться к нашей миссии по защите и сохранению морской среды и всего живого, что в ней находится. Моря являются жизненно важной частью нашей планеты, и наша ответственность - обеспечивать их защиту для будущих поколений. Работая вместе, мы можем действительно изменить состояние морей, океанов и их обитателей. Есть много способов, как вы можете помочь! Вы можете начать с того, чтобы наблюдать и сообщать о загрязнении и необычных ситуациях на побережье рядом с вами. Мы обещаем, что позаботимся о остальном! Мы рады предложить вам членство в нашем сообществе и с нетерпением ждем, когда вы станете его частью. Заранее благодарим за вашу преданность и стремление сохранять море!',
    welcomeTextMember:
      'Мы так рады, что вы присоединились к нам в нашей миссии по защите и сохранению морской среды.',
    welcomeText1Member: 'Начните свой вклад, опубликовав отчет!',
    gategoriesTitle: 'Особые Наблюдения',
    catDes: 'Публикация отчета возможна только с учетной записью.',
  },
  reports: {
    countReports: 'Количество отчетов за последние 24 часа: ',
    jelly: 'Медузы',
    storm: 'Шторм',
    meteorological: 'Метеорологические условия',
    pollutants: 'Загрязнители моря и побережья',
    other: 'Другие проблемы',
    subtitle:
      'Если вы заметили что-то необычное, связанное с другими проблемами, дайте нам знать.',
    subtitleJ:
      'Здесь вы можете сообщить о своих наблюдениях относительно наличия и концентрации медуз.',
    subtitleA:
      'Здесь вы можете сообщить о своих наблюдениях, связанных со штормами и штормовой активностью.',
    subtitleM:
      'Здесь вы можете сообщить о своих наблюдениях, связанных с направлением и скоростью ветра, наличием тумана, штормовым морем и т.д.',
    subtitleP:
      'Здесь вы можете сообщить о своих наблюдениях по выявлению различных видов загрязнений в морской воде и на побережье.',
    subtitleO:
      'Здесь вы можете сообщить о проблемах, связанных с морской средой, которые не входят в другие категории.',
    createRbtn: 'Создать отчет',
  },
  filters: {
    filtersTitle: 'Фильтры:',
    jelly: 'Медузы',
    storm: 'Шторм',
    meteorological: 'Метеорология',
    pollutants: 'Загрязнители',
    other: 'Другое',
    all: 'Все',
  },
  map: {
    mapTitle: 'Легенда карты',
    jelly: 'Медузы',
    storm: 'Штормы',
    meteorological: 'Метеорологические условия',
    pollutants: 'Загрязнители',
    other: 'Другие проблемы',
    repoertsCard: {
      quantity: 'Количество:',
      typeofOrganism: 'Тип организма:',
      comments: 'Комментарии:',
      // meteo
      fogtype: 'Тип тумана:',
      seaSwellType: 'Тип волнения моря',
      windType: 'Тип ветра:',
      gigFloatingObjects: 'Большие плавающие объекты:',
      oilPolutionYes: 'Нефтяное загрязнение: Да',
      oilPolutionNo: 'Нефтяное загрязнение: Нет',
      biologicalPolutionYes: 'Биологическое загрязнение: Да',
      biologicalPolutionNo: 'Биологическое загрязнение: Нет',
      plasticPolutionYes: 'Пластиковое загрязнение: Да',
      plasticPolutionNo: 'Пластиковое загрязнение: Нет',
      stromType: 'Тип бури:',
      otherPoll: 'Другое загрязнение:',
    },
  },
  // login
  login: {
    signin: 'Вход',
    loginInSystem: 'Вход в систему?',
    logInsysDescription:
      'Требуется вход для возможности сообщать о наблюдаемых морских экологических активностях и условиях!',
    btnLogin: 'Войти',
    descriptionSignin: {
      main: 'Если у вас нет аккаунта, вы можете',
      reg: 'Зарегистрироваться',
      now: 'сейчас',
    },
    fieldsSignIn: {
      userName: 'Имя пользователя',
      subtitle: 'Пожалуйста, введите ваше имя пользователя.',
      password: 'Пароль',
      subtitlepass: 'Пожалуйста, введите свой пароль.',
      errorMessage: 'Вы ввели неверное имя пользователя или пароль.',
      requiredFieldError: 'Это поле обязательно к заполнению!',
      validEmailAddressError:
        'Пожалуйста, введите действительный адрес электронной почты!',
      emailErrorCharacters:
        'Адрес электронной почты не может превышать 100 символов!',
      passErrorCh: 'Пароль не может превышать 30 символов!',
      forgotPassword: 'Забыли пароль',
      enterValidEmail: 'Пожалуйста, введите ваш адрес электронной почты:',
      forgotPasswordMessage1: 'Забыли пароль?',
      forgotPasswordMessage2:
        'Введите адрес электронной почты, с которым вы зарегистрированы, и вы получите письмо для изменения пароля.',
      changePassTitle: 'Измените свой пароль',
      newPass: 'Пожалуйста, введите новый пароль:',
      confirmNewPass: 'Подтвердите новый пароль:',
      showPass: 'Показать пароль',
      confirmBtn: 'Подтвердить',
      passMatch: 'Пароли не совпадают!',
      successSubitMessage:
        'Через 10 секунд мы перенаправим вас на страницу входа, чтобы вы могли войти с новым паролем!',
      successMessageForPass: 'Пароль успешно изменен!',
      sendEmail: 'Отправить электронное письмо для смены пароля',
      resendEmail: 'Отправить снова',
      successEmailmsg: 'Письмо успешно отправлено на:',
    },
  },
  // register
  register: {
    signup: 'Регистрация',
    registerInSystem: 'Зачем вам нужно создать аккаунт?',
    registerInsysDescription:
      'Этот аккаунт может быть использован для доступа к более продвинутым услугам.',
    btnSignUp: 'Зарегистрироваться',
    descriptionSignup: {
      main: 'Если у вас уже есть аккаунт, вы можете',
      login: 'Войти',
      now: 'сейчас!',
    },
    fieldsSignUp: {
      // Full name
      fullName: 'Полное имя',
      subtitleFN: 'Пожалуйста, введите ваше имя и фамилию.',
      errorMsgName:
        'Это поле должно содержать ваше имя и фамилию, и первые две буквы имени и фамилии должны быть заглавными!',
      errorMsgReg: 'Регистрация не удалась. Пожалуйста, попробуйте снова.',
      // User name
      username: 'Имя пользователя',
      subtitleUserName:
        'Пожалуйста, введите имя пользователя (максимум 20 символов).',
      errorMsgUserName: 'Имя пользователя должно быть от 3 до 20 символов.',
      // Email
      email: 'Электронная почта',
      subtitleEmail:
        'Пожалуйста, введите действительный адрес электронной почты.',
      errorMsgEmail: 'Это недействительный адрес электронной почты.',
      // Pass
      password: 'Пароль',
      subtitlePass:
        'Пароль должен содержать: Минимум: 8 символов (1 заглавная буква, 1 строчная буква, 1 специальный символ, 1 цифра); Максимум: 30 символов.',
      errorMsgPass: 'Неверный пароль!',
      // Error message foor all fields for registration
      requiredFieldError: 'Это поле обязательно для заполнения!',
    },
  },
  // profile board
  profile: {
    personalInfo: {
      title: 'Личная информация',
      userName: 'Имя пользователя',
      emailAddress: 'Адрес электронной почты',
      inf: 'Инфо!',
      info: 'Если вы хотите опубликовать отчет, перейдите на',
      home: 'Главная',
      page: 'страницу!',
    },
    numberofRep: {
      title: 'Опубликованные отчеты',
      jelly: 'Медузы',
      storm: 'Шторм',
      meteorological: 'Метеорологические условия',
      polu: 'Загрязнители',
      other: 'Другие проблемы',
    },
  },
  //modals
  modals: {
    fillAll: 'Пожалуйста, заполните все поля!',
    sent: 'Отчет отправлен!',
    dragndrop: 'Перетащите или нажмите для загрузки',
    jellyM: {
      titleJ: 'Отчет о медузах',
      titleA: 'Шторм',
      titleM: 'Метеорологические параметры',
      titleP: 'Загрязнители моря и побережья',
      titleO: 'Другие проблемы',
      reportTitle: 'Заголовок:',
      reportersName: 'Имя репортера:',
      dateAndTime: 'Дата и время:',
      info: 'Инфо!',
      infoTxt: 'Этот раздел требует доступа к вашему местоположению!',
      infoGeolocation:
        'Нажмите на карту, чтобы выбрать точное местоположение отчета!',
      yourLocation: 'Ваше местоположение:',
      quantity: 'Количество:',
      des: 'Описание:',
      type: 'Тип организма:',
      attachImage: 'Прикрепить фото:',
      stormType: 'Тип шторма:',
      wind: 'Ветер:',
      wave: 'Волны:',
      fog: 'Туман:',
      typeContamination: 'Выберите тип загрязнения (может быть несколько):',
      oilPolution: 'Нефтяное загрязнение',
      bioPolution: 'Биологическое загрязнение',
      plasticPolution: 'Пластиковое загрязнение',
      bigfloatingpollution: 'Крупные плавающие загрязнители:',
      commentP: 'Другие загрязнители или добавьте комментарии:',
      optionStorm: 'Шторм',
      optionThunderstorm: 'Гроза',
      optionRain: 'Дождь',
      optionHailstorm: 'Град',
      optionNostorm: 'Без шторма',
      pleaseSelect: 'Пожалуйста, выберите',
      optionHurricane: 'Ураган',
      optionStrong: 'Сильный',
      optionModerate: 'Умеренный',
      optionWeak: 'Слабый',
      optionNowind: 'Без ветра',
      optionStrongW: 'Сильный: 6 м.',
      optionModerateW: 'Умеренный: 5 м',
      optionWeakW: 'Слабый: 1 - 2 м.',
      optionNowaves: 'Без волн',
      optionVeryT: 'Очень густой (видимость менее 50 метров)',
      optionT: 'Густой (видимость между 50 и 200 метрами)',
      optionModerateF: 'Умеренный (видимость между 200 и 500 метрами)',
      optionWeakF: 'Легкий (видимость между 500 и 1000 метрами)',
      optionNofog: 'Без тумана',
      to: 'до',
      selectFile: 'Пожалуйста, выберите файл для отправки!',
      selectStorm: 'Пожалуйста, выберите тип шторма для отправки!',
      selectquantity: 'Пожалуйста, выберите количество для отправки',
      selectValue:
        'Пожалуйста, выберите значения из выпадающего списка для отправки!',
      submitReport: 'Отправить отчет',
    },
  },
  //history
  history: {
    title: 'История',
    description: 'Проверка отчетов, которые вы опубликовали до сих пор!',
    tabJ: 'Отчеты о медузах',
    tabA: 'Отчеты о штормах',
    tabP: 'Отчеты о загрязнителях',
    tabM: 'Метеорологические отчеты',
    tabO: 'Прочие отчеты',
    titleCard: 'Заголовок:',
    detailsCard: 'Детали:',
  },
  // About us
  aboutUs: {
    title: 'О НАС',
    mission: 'Наша миссия',
    welcome:
      'Добро пожаловать в наше сообщество, посвященное сохранению моря! Мы представляем собой большую группу страстных энтузиастов, которые разделяют общую цель защиты наших океанов и морской жизни.',
    ourCommunity:
      'Наше сообщество было основано на убеждении, что здоровье океана имеет решающее значение для здоровья нашей планеты, и мы стремимся повышать осведомленность и вдохновлять на действия в целях сохранения океана для будущих поколений. Мы стремимся продвигать устойчивые практики, сокращать загрязнение и защищать исчезающие виды.',
    weBelieve:
      'Мы считаем, что это наша ответственность предпринимать действия для защиты океана и его обитателей, и мы приглашаем всех, кто разделяет нашу страсть, присоединиться к нам в нашей миссии. Работая вместе, мы можем оказать положительное воздействие на здоровье океана и создать лучшее будущее для нашей планеты.',
    ourCoismade:
      'Наше сообщество состоит из людей различного происхождения, включая экологов, ученых, педагогов и обеспокоенных граждан. Мы ценим разнообразие и верим, что объединяясь и делаясь своими знаниями и опытом, мы можем достичь большего.',
    thankyou:
      'Спасибо за ваш интерес к нашему сообществу! Мы приглашаем вас присоединиться к нам в нашей миссии по сохранению моря и защите здоровья нашей планеты!',
  },
  // Contacts
  contacts: {
    title: 'Свяжитесь с нами',
    emergency: 'Аварийный контакт 112',
    emDes:
      'Если вы заметили что-то необычное или сигнал, который следует немедленно передать контрольным органам, пожалуйста, свяжитесь с:',
    iara: 'ИАРА - Исполнительное агентство по рыболовству и аквакультуре',
    iaraContact: 'ИАРА - страница контактной формы',
    iaraContactForm: 'ИАРА - Контактная форма',
  },
  // privacy
  privacy: {
    title: 'ICC - Политика конфиденциальности',
    agreement:
      'На сайте ICC, доступном по веб-адресу, одним из наших основных приоритетов является конфиденциальность наших посетителей. Этот документ по политике конфиденциальности разработан для того, чтобы помочь вам понять, как мы собираем, используем и обеспечиваем безопасность информации, которую вы предоставляете нам.',
    agrTitle: '1. Соглашение',
    agrSubtitle:
      'Посещая наш сайт, вы принимаете нашу Политику конфиденциальности и соглашаетесь с ее условиями использования',
    subLink: ' (ссылка).',
    //
    informationCollectedT: '2. Собранная информация',
    informationCollectedAutomatically: 'Автоматически собранная информация',
    infoSubtitle:
      'Посещая наш сайт, наши серверы автоматически собирают информацию, предоставляемую вашим браузером. Эти данные могут включать информацию, такую как IP-адрес, тип и версия браузера, языковые предпочтения, исходящие/входящие страницы и URL-адреса, время и дату доступа, местоположение и другую неперсональную информацию.',
    infoSubtitle1: 'Информация, предоставленная вами при регистрации аккаунта',
    infoSubtitle11:
      'Чтобы стать подписчиком Сервиса, вам нужно будет создать личный профиль, зарегистрировавшись в Сервисе. Для регистрации вам потребуется ввести свой адрес электронной почты, создать имя пользователя и пароль. Регистрируясь, вы разрешаете нам собирать, хранить и использовать ваш адрес электронной почты в соответствии с этой Политикой конфиденциальности.',
    privacyOfChildren: 'Конфиденциальность детей',
    subTitleChildren:
      'Веб-сайт не сознательно собирает или запрашивает информацию от лиц младше 14 лет или не позволяет лицам младше 14 лет регистрироваться на Сервисе. Если мы узнаем, что собрали личную информацию от кого-либо младше 14 лет без согласия родителей или опекунов, мы немедленно удалим эту информацию. Если у вас есть основания полагать, что мы собрали такую информацию, просим связаться с нами по адресу: contact@...........',
    //
    useShareInfo: '3. Использование и распространение информации',
    useShareInfoSubtitle:
      'Мы не продаем, не обмениваемся, не сдаем в аренду и не распространяем вашу личную информацию для маркетинговых целей без вашего согласия, кроме как указано в настоящей Политике конфиденциальности. Мы делимся личной информацией с поставщиками услуг для Компании.',
    useShareInfoSubtitle1:
      'Мы можем делиться личной информацией с внешними сторонами, если у нас есть искреннее убеждение, что доступ, использование, сохранение или раскрытие информации обоснованно для выполнения какого-либо законного процесса или выполнимого государственного запроса.',
    useShareInfoSubtitle2:
      'В целом, мы используем неличную информацию для улучшения Сервиса и настройки пользовательского опыта. Мы также агрегируем неличную информацию для отслеживания трендов и анализа шаблонов использования сайта. Настоящая Политика конфиденциальности никоим образом не ограничивает наше использование или раскрытие неличной информации, и мы сохраняем за собой право использовать и раскрывать такую неличную информацию нашим партнерам, рекламодателям и другим третьим сторонам по нашему усмотрению.',
    useShareInfoSubtitle3:
      'Мы рекомендуем периодически проверять Веб-сайт, чтобы быть в курсе того, как используется ваша информация.',
    //
    protectionTitle: '4. Меры защиты',
    protectionSubtitle:
      'Мы внедряем меры безопасности, направленные на защиту вашей информации от несанкционированного доступа. Ваша учетная запись защищена вашим паролем. Кроме того, мы дополнительно защищаем вашу информацию от потенциальных нарушений безопасности, внедряя определенные технологические меры безопасности. Однако эти меры не гарантируют, что ваша информация не будет доступна, разглашена, изменена или уничтожена вследствие нарушения таких мер безопасности. Используя наш Сервис, вы признаете, что понимаете и согласны с этими рисками.',
    userRigth:
      'Права пользователя относительно использования личной информации',
    userRigthSubtitle:
      'У вас есть право в любое время предотвратить наши контакты с вами в маркетинговых целях. Когда мы отправляем рекламное сообщение пользователю, пользователь может отказаться от дальнейших рекламных сообщений, следуя инструкциям, приведенным в каждом рекламном письме.',
    linkToOtherSite: 'Ссылки на другие веб-сайты',
    linkToOthersSubtitle:
      'В рамках Сервиса мы можем предоставлять ссылки на другие веб-сайты или приложения, которые не принадлежат нам и не контролируются нами. Имейте в виду, что мы не несем ответственности за политику конфиденциальности, используемую этими веб-сайтами, а также за информацию или контент, которые они содержат. Настоящая Политика конфиденциальности применяется исключительно к информации, собранной нами через Сайт и Сервис. Мы рекомендуем вам прочитать условия конфиденциальности других веб-сайтов, прежде чем приступить к их использованию.',
    //
    changes: '5. Изменения и поправки',
    changesSubtitle:
      'Мы оставляем за собой право в любое время изменять настоящую политику и наши Условия использования. Мы уведомим вас об изменениях в нашей Политике конфиденциальности, отправив вам электронное письмо на контактную информацию, которую вы предоставили. Пожалуйста, периодически проверяйте веб-сайт на наличие обновлений.',
    //
    cookies: '6. Куки (Cookies)',
    cookiesSubtitle:
      'Веб-сайт и услуги ICC могут использовать аналитические инструменты от третьих сторон, которые используют `cookies`. Эта информация используется для улучшения опыта пользователей и мониторинга производительности веб-сайта и услуг.',
    //
    contactTitle: '7. Контакты',
    contactSubtitle:
      'В случае возникновения вопросов или опасений относительно этой Политики или практики этого веб-сайта, мы рекомендуем вам связаться с нами по адресу:',
    tuVarna: 'Технический университет Варны',
    tuAddress: '1, ул. Студенская, 9000 Варна, Болгария',
    cnt: 'contact@.................',
  },
};
