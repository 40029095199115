import "../../Styles/Login.css";

import { Navigate } from "react-router-dom";
import React from "react";
import checkIcon from "../../Assetss/check-icon.png";
import { useSelector } from "react-redux";

function AccountSuccessCreated() {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="registration-success__section">
      <div className="alert alert-success success--text " role="alert">
        <h1>Account successfully created</h1>
        <div className="check__box">
          <img className="check--icon" src={checkIcon} alt="check" />
        </div>
        <p>
          Go to the
          <a className="go-to-homepage ml-2 mr-2" href="/">
            Home page
          </a>
          and start release your report.
        </p>
      </div>
      {/* SOme image */}
    </div>
  );
}

export default AccountSuccessCreated;
