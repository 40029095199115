import "../.././Styles/inform-pages/Contact.css";

import React from "react";
import message from "../../Assetss/message.png";
import phone from "../../Assetss/phone.png";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contact-page mt-4">
      <h1 className="mb-3 text-center">{t("contacts.title")}</h1>
      <div className="contact-page-wrapper">
        <div className="row justify-content-center">
          <div className="col">
            <div className="card card-contact">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <img className="trin-trin" src={phone} alt="phone" />
                </div>
                <h6 className="card-subtitle mb-2 text-muted text-center mt-3">
                  {t("contacts.emergency")}
                </h6>
                <p className="card-text text-center min-height--text">
                  {t("contacts.emDes")}
                </p>
                <div className="btn-emergenct--red">
                  <a className="card-text emergency-text" href="tel:112">
                    112
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-contact">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <img className="bounce-message" src={message} alt="message" />
                </div>
                <h6 className="card-subtitle mb-2 text-muted text-center mt-3">
                  {t("contacts.iara")}
                </h6>
                <p className="card-text text-center min-height--text">
                  {t("contacts.iaraContact")}
                </p>
                <div className="test-test">
                  <a
                    className="card-text iara-text"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://iara.government.bg/wps/portal/iara-web/contacts"
                  >
                    {t("contacts.iaraContactForm")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
