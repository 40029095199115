import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import '../../Styles/user-profile/UserHistory.css';
import '../../Styles/user-profile/user-accunt.css';

// D:\Докторантура\ILIAD\IliadSeaProject\IliadSeaProject\ClientApp\src\Assetss\check-icon.png

// import 'bootstrap/dist/css/bootstrap.min.css';
import GetMyReportsJellyfish from './MyReports/GetMyReportsJellyfish';
import GetMyReportsAtypical from './MyReports/GetMyReportsAtypical';
import GetMyReportsPollutants from './MyReports/GetMyReportsPollutants';
import GetMyReportsMeteorological from './MyReports/GetMyReportsMeteorological';
import GetMyReporstOther from './MyReports/GetMyReportsOther';

import { useTranslation } from 'react-i18next';

function History() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="account-contaner">
      <div className="account-main-content history-content">
        <div className="il__profile-content__container">
          <div className="account-wapper p-3">
            <h1 className="mb-4">{t('history.title')}</h1>
            <h2 className="mb-4">{t('history.description')}</h2>
            <div>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#jellyfishrep"
                    role="tab"
                    aria-controls="jellyfishrep"
                    aria-selected="false"
                  >
                    {t('history.tabJ')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#atypical"
                    role="tab"
                    aria-controls="atypical"
                    aria-selected="false"
                  >
                    {t('history.tabA')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#pollutants"
                    role="tab"
                    aria-controls="pollutants"
                    aria-selected="false"
                  >
                    {t('history.tabP')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#meteorological"
                    role="tab"
                    aria-controls="meteorological"
                    aria-selected="false"
                  >
                    {t('history.tabM')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#others"
                    role="tab"
                    aria-controls="others"
                    aria-selected="false"
                  >
                    {t('history.tabO')}
                  </a>
                </li>
              </ul>
              <div
                className="tab-content tab-content--history"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="jellyfishrep"
                  role="tabpanel"
                  aria-labelledby="jellyfish-tab"
                >
                  {<GetMyReportsJellyfish />}
                </div>
                <div
                  className="tab-pane fade"
                  id="atypical"
                  role="tabpanel"
                  aria-labelledby="atypical-tab"
                >
                  {<GetMyReportsAtypical />}
                </div>
                <div
                  className="tab-pane fade"
                  id="pollutants"
                  role="tabpanel"
                  aria-labelledby="pollutants-tab"
                >
                  {<GetMyReportsPollutants />}
                </div>
                <div
                  className="tab-pane fade"
                  id="meteorological"
                  role="tabpanel"
                  aria-labelledby="meteorological-tab"
                >
                  {<GetMyReportsMeteorological />}
                </div>
                <div
                  className="tab-pane fade"
                  id="others"
                  role="tabpanel"
                  aria-labelledby="others-tab"
                >
                  {<GetMyReporstOther />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
