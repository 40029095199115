import React from "react";

const Pagination = ({ totalPages, handleClick }) => {
  const pages = [...Array(totalPages).keys()].map((num) => num + 1);

  return (
    <div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className="page-item">
            {/* <a class="page-link"  aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a> */}
          </li>
          {pages.map((num) => (
            <li className="page-item" key={num}>
              <button className="page-link" onClick={() => handleClick(num)}>
                {num}
              </button>
            </li>
          ))}
          <li className="page-item">
            {/* <a class="page-link"  aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a> */}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
