import "bootstrap/dist/js/bootstrap.js";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import React, { useEffect, useState } from "react";

import { DateRangePicker } from "react-date-range";
import LoadingSpin from "react-loading-spin";
import Pagination from "./rep/Pagination";
import { REPORT_PER_PAGE } from "./utils/constants";
import ReportsO from "./reports/reportO";
import axios from "axios";
import configUrl from "../../../configuration-data.json";

function ReporstOther() {
  const test = JSON.parse(localStorage.getItem("user"));

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tableChanged, setTableChanged] = useState(true);

  useEffect(() => {
    // console.log(jsonDate);
    if (tableChanged) {
      setLoading(true);
      axios
        .get(configUrl.SERVER_URL + "BaseReports/GetAllReports", {
          // jsonDate,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json: charset=utf-8",
            Authorization: `Bearer ${test.token}`,
          },
        })
        .then((response) => {
          setPosts(response.data);
          setLoading(false);
          setTotalPages(Math.ceil(response.data.length / REPORT_PER_PAGE));
        });
      setTableChanged(false);
    }
  }, [tableChanged, test.token]);

  const handleTableChange = (value) => {
    setTableChanged(value);
  };

  const handleSelect = (date) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleClick = (num) => {
    setPage(num);
  };

  return (
    <div className="admin__section-body">
      <p className="total-num-reports ds-flex justify-content-center">
        {loading ? (
          <LoadingSpin className="ml-3" size="60px" />
        ) : (
          <>Total Published reports : {posts.length}</>
        )}
      </p>
      <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
      {loading ? (
        <div className="row justify-content-center">
          <LoadingSpin className="ml-3" size="60px" />
        </div>
      ) : (
        <>
          <div className="row">
            <ReportsO
              reports={posts}
              page={page}
              handleTableChange={handleTableChange}
            />{" "}
          </div>
          <Pagination totalPages={totalPages} handleClick={handleClick} />
          <p>Page {page}</p>
        </>
      )}
    </div>
  );
}

export default ReporstOther;
