import React from "react";
import { useTranslation } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-and-terms mt-4">
      <h1 className="txt-align-c">{t("privacy.title")}</h1>
      <p>{t("privacy.agreement")}</p>
      <br />
      <h2 className="txt-align-c">{t("privacy.agrTitle")}</h2>
      <p>
        {t("privacy.agrSubtitle")}
        <a href="/terms-of-use">{t("privacy.subLink")}</a>
        <br />
      </p>
      <h2 className="txt-align-c">{t("privacy.informationCollectedT")}</h2>
      <h5 className="txt-align-c">
        {t("privacy.informationCollectedAutomatically")}
      </h5>
      <p>
        {t("privacy.infoSubtitle")}
        <br />
      </p>
      <h5 className="txt-align-c">
        {t("privacy.infoSubtitle1")}
        <br />
      </h5>
      <p>
        {t("privacy.infoSubtitle11")}
        <br />
      </p>
      <h2 className="txt-align-c">
        {t("privacy.privacyOfChildren")}
        <br />
      </h2>
      <p>
        {t("privacy.subTitleChildren")}
        <br />
      </p>
      <h2 className="txt-align-c"> {t("privacy.useShareInfo")}</h2>
      {/*  */}
      <p>
        {t("privacy.useShareInfoSubtitle")}
        <br />
      </p>
      <p>
        {t("privacy.useShareInfoSubtitle1")}
        <br />
      </p>
      <p>
        {t("privacy.useShareInfoSubtitle2")}
        <br />
      </p>
      <p>
        {t("privacy.useShareInfoSubtitle3")}
        <br />
      </p>
      {/*  */}
      <h2 className="txt-align-c">{t("privacy.protectionTitle")}</h2>
      <p>
        {t("privacy.protectionSubtitle")}
        <br />
        {t("privacy.userRigth")}
        <br />
        {t("privacy.userRigthSubtitle")}
        <br />
        {t("privacy.linkToOtherSite")}
        <br />
        {t("privacy.linkToOthersSubtitle")}
        <br />
      </p>
      <h2 className="txt-align-c">{t("privacy.changes")}</h2>
      <p>{t("privacy.changesSubtitle")}</p>
      <br />
      <h2 className="txt-align-c">{t("privacy.cookies")}</h2>
      <p>{t("privacy.cookiesSubtitle")}</p>
      <br />
      <h2 className="txt-align-c">{t("privacy.contactTitle")}</h2>
      <p>
        {t("privacy.contactSubtitle")}
        <br />
        {t("privacy.tuVarna")}
        <br />
        {t("privacy.tuAddress")}
        <br />
        {t("privacy.cnt")}
      </p>
      <br />
    </div>
  );
}

export default Privacy;
