import React from 'react';
import { REPORT_PER_PAGE } from './../utils/constants';
import Other from './../rep/Other';

const ReportsO = ({ reports, page, handleTableChange }) => {
  const startIndex = (page - 1) * REPORT_PER_PAGE;
  const selectedReports = reports.slice(
    startIndex,
    startIndex + REPORT_PER_PAGE
  );
  return selectedReports.map((reports) => (
    <>
      <Other reports={reports} handleTableChange={handleTableChange} />
    </>
  ));
};

export default ReportsO;
